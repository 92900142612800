import { connect } from 'react-redux';
import { billsActionCreator } from '../../modules/bills/actions';
import { getAccountsById, getAccountsSelected } from '../../modules/accounts/selector';
import { getBills, getBillsSelected } from '../../modules/bills/selector';
import { TicketsComponent } from './tickets.component';
import { getDates } from '../../modules/dates/selector';
import { ticketsActionCreator } from '../../modules/tickets/actions';
import { getTickets, getTicketsSelectedIds } from '../../modules/tickets/selector';
import { API_ACTIONS } from '../../server/API_ACTIONS';
import { ServerService } from '../../server/ServerService';
import { airReaderRoutes } from '../../services/routesService';

const mapStateToProps = state => {
  return {
    tickets: getTickets(state).filter(ticket => !ticket.canceled),
    accountsById: getAccountsById(state),
    accountsSelected: getAccountsSelected(state),
    billsSelected: getBillsSelected(state),
    dates: getDates(state),
    ticketsSelectedIds: getTicketsSelectedIds(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTickets: () => {
      dispatch(ticketsActionCreator.GET_TICKETS_REQUEST());
    },
    selectTicket: ticket => {
      console.log(ticket);
      dispatch(ticketsActionCreator.SELECT_TICKET(ticket));
    },
    addCustomerMetaData: async ({ ticketId, customerMetaData }) => {
      const action = {
        type: API_ACTIONS.TICKETS.ADD_CUSTOMER_META_DATA,
        payload: { ticketId, customerMetaData },
      };
      await dispatch(action);
      try {
        const result = await ServerService.postActionToServer(action);
        // const actionSuccess =
        dispatch({ type: API_ACTIONS.TICKETS.GET_SUCCESS, payload: [result.data] });
        console.log(result.data);
      } catch (e) {
        console.log(e);
      }
    },
    goToBillCreation:  async () => {
      ownProps.history && ownProps.history.push(airReaderRoutes.BILLS_CREATE.to);
    }
  };
};

export const Tickets = connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketsComponent);
