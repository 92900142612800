import { IRootReducer } from '../rootReducer';
import { getAccountsSelectedIds } from '../accounts/selector';
import { getDatesYYMMDD } from '../dates/selector';

export const getCreditNotes = (state: IRootReducer) => {
  const accountsSelectedIds = getAccountsSelectedIds(state);
  const dates = getDatesYYMMDD(state);
  console.log(dates);
  return Object.values(state.tickets.ticketsById)
    .filter(ticket => ticket.creditNoteMetaData)
    .sort((a, b) => (a.date > b.date ? 1 : -1));
};
export const getCreditNoteById = (state: IRootReducer, id) => state.creditNotes.creditNotesById[id];
export const getCreditNotesSelectedIds = (state: IRootReducer) =>
  state.creditNotes.creditNotesSelected;
export const getCreditNotesSelected = (state: IRootReducer) =>
  state.creditNotes.creditNotesSelected.map(
    ticketId => state.creditNotes.creditNotesById[ticketId]
  );
export const getCreditNotesByIds = (state: IRootReducer, creditNotesId: string[]) =>
  state.creditNotes.filter(creditNote => creditNotesId.includes[creditNote._id]);
