import React from 'react';
import { SearchBar } from '../../components/SearchBar/SearchBar';
import { Button } from '../../easy_lib/Basics';
import { Column, Row } from '../../easy_lib/Layout';
import { theme } from '../../theme';

export class AccountsComponent extends React.Component {
  state = { accountNameFilter: '' };

  componentDidMount() {
    this.props.accounts.length === 0 && this.props.getAccounts();
  }

  render() {
    const accountsToShow = this.state.accountNameFilter
      ? this.props.accounts.filter(
          account =>
            account.name.toLowerCase().indexOf(this.state.accountNameFilter.toLowerCase()) > -1
        )
      : this.props.accounts;
    const areAllAccountsSelected =
      this.props.accountsSelected.length === this.props.accounts.length;
    return (
      <Column>
        <Row
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: theme.getSpacing(5),
          }}
        >
          <div style={{ fontSize: theme.fontSizes.xl, marginRight: theme.getSpacing(2) }}>
            Accounts :{' '}
          </div>
          <Button small success style={{ padding: 5 }} onClick={this.props.getAccounts}>
            Refresh accounts
          </Button>
          <Button
            small
            info={!areAllAccountsSelected}
            danger={areAllAccountsSelected}
            style={{ padding: 5, marginLeft: 15 }}
            onClick={
              areAllAccountsSelected
                ? () => this.props.selectAccount([])
                : () => this.props.selectAccount(this.props.accounts)
            }
          >
            {areAllAccountsSelected ? 'Tout désélectionner' : 'Tout selectionner'}
          </Button>
        </Row>
        <SearchBar
          label={'Search by name'}
          placeholder={'Write a customer name here…'}
          onChange={e => this.setState({ accountNameFilter: e.target.value })}
        />
        {accountsToShow.map(account => (
          <div
            key={account._id}
            onClick={() => this.props.selectAccount([account])}
            style={{
              padding: theme.getSpacing(2),
              fontWeight: this.props.accountsSelected.map(x => x._id).includes(account._id)
                ? '800'
                : '',
            }}
          >
            {account.name}
          </div>
        ))}
      </Column>
    );
  }
}
