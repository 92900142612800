import React from 'react';

export const DataTableFromArray = ({ dataArray }) => (
  <table>
    {dataArray.map(dataRow => (
      <tr>
        {dataRow.map(dataCell => (
          <td>{dataCell}</td>
        ))}
      </tr>
    ))}
  </table>
);
