export const API_ACTIONS = {
  AUTHENTICATION: {
    LOGIN: 'AUTHENTICATION/LOGIN',
  },
  ACCOUNTS: {
    GET: 'ACCOUNTS/GET',
  },
  BILLS: {
    GET: 'BILLS/GET',
    CREATE: 'BILLS/CREATE',
  },
  TICKETS: {
    GET: 'TICKETS/GET',
    GET_SUCCESS: 'GET_TICKETS_SUCCESS',
    GET_BY_IDS: 'TICKETS/GET_BY_IDS',
    ADD_CUSTOMER_META_DATA: 'TICKETS/ADD_CUSTOMER_META_DATA',
    SELECT_TICKET: 'SELECT_TICKET',
  },
  CREDIT_NOTES: {
    GET: 'CREDIT_NOTES/GET',
    GET_BC_DATA: 'CREDIT_NOTES/GET_BC_DATA'
  },
  STASH: {
    ADD: 'STASH/ADD',
  },
};

export const LOCAL_ACTIONS = {
  CREDIT_NOTES: {
    GET_SUCCESS: 'CREDIT_NOTES/GET/SUCCESS',
    UPDATE_SUCCESS: 'CREDIT_NOTES/UPDATE',
  },
  BILLS: {
    CREATE_SUCCESS: 'BILLS/CREATE_SUCCESS'
  }
}

export const ACTIONS_GENERATOR = {
  TICKETS: {
    GET_BY_IDS: ticketsIds => ({ type: API_ACTIONS.TICKETS.GET_BY_IDS, payload: ticketsIds }),
    GET_SUCCESS: tickets => ({ type: API_ACTIONS.TICKETS.GET_SUCCESS, payload: tickets }),
    SELECT_TICKET: tickets => ({ type: API_ACTIONS.TICKETS.SELECT_TICKET, payload: tickets }),
  },
  CREDIT_NOTE: {
    GET_BC_DATA: creditNoteId => ({type: API_ACTIONS.CREDIT_NOTES.GET_BC_DATA, payload: creditNoteId}),
    GET_SUCCESS : creditNotes => ({type: LOCAL_ACTIONS.CREDIT_NOTES.GET_SUCCESS, payload: creditNotes}),
    UPDATE_SUCCESS : creditNote => ({type: LOCAL_ACTIONS.CREDIT_NOTES.UPDATE_SUCCESS, payload: creditNote})
  },
  BILLS: {
    CREATE_SUCCESS: (bill) => ({type: LOCAL_ACTIONS.BILLS.CREATE_SUCCESS, payload: bill})
  }
};
