import React from 'react';
import { Row } from '../../easy_lib/Layout';
import { airReaderRoutes } from '../../services/routesService';
import { NavLink } from 'react-router-dom';

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  button: color => ({
    padding: 10,
    margin: '0 10px',
    color
  }),
  buttonsContainer: {
      marginTop: 20
  }
};

const linkButtons = [
  { ...airReaderRoutes.TICKET_STATES, className: 'danger' },
  { ...airReaderRoutes.CREDIT_NOTE_STATE, className: 'success' },
  { ...airReaderRoutes.STATS_AND_GRAPH, className: 'info' },
];

export const WelcomePage = () => (
  <div style={styles.container}>
    <h1>Bienvenue sur le portail AFV travel</h1>
    <div>
      Vous trouverez tout le détail des billets émis chez nous, les statistiques et graphiques
      associés
    </div>
    <Row style={styles.buttonsContainer}>
      {linkButtons.map(linkButton => (
        <NavLink
          exact
          style={styles.menuItem}
          key={linkButton.to}
          activeStyle={styles.activeLink}
          className={'btn ' + linkButton.className}
          to={linkButton.to}
        //   style={styles.button(linkButton.color)}
        >
          {linkButton.text}
        </NavLink>
      ))}
    </Row>
  </div>
);
