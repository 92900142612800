import axiosInstance from './axiosInstance';
import { ObjectID } from 'bson';

const postActionToServer = (action, URL_PATH = '') => {
  return axiosInstance.post(URL_PATH, { _id: new ObjectID(), ...action });
};

export class ServerService {
  static postToServer(URL_PATH, obj) {
    return axiosInstance.post(URL_PATH, obj);
  }

  static postActionToServer = postActionToServer;

  static CREDIT_NOTE = {
    GET_TICKET_AND_CREDIT_NOTE: ticketId =>
      ServerService.postActionToServer(
        { type: 'GET TICKET AND CREDIT NOTE', payload: { ticketId } },
        'credit_notes/ticket',
      ),
    DELETE: ticketId =>
      ServerService.postActionToServer(
        { type: 'DELETE CREDIT NOTE', payload: { ticketId } },
        'credit_notes/delete',
      ),
    UPSERT: (ticketId, data) =>
      ServerService.postActionToServer(
        {
          type: 'UPSERT CREDIT NOTE',
          payload: { data, ticketId },
        },
        'credit_notes/add',
      ),
    CREATE_BILL: ({ tickets, billNumber, customerAccount, remarks }) =>
      ServerService.postActionToServer(
        {
          type: 'CREATE CREDIT NOTE BILL',
          payload: { tickets, billNumber, customerAccount, remarks },
        },
        'credit_notes/bill',
      ),
    GET_BILLS: () =>
      ServerService.postActionToServer({ type: 'GET CREDIT NOTES BILLS' }, 'credit_notes/bill/all'),
    GET_BILL: id =>
      ServerService.postActionToServer(
        { type: 'GET CREDIT NOTES BILL' },
        `credit_notes/bill/details/${id}`,
      ),
    DELETE_BILL: id =>
      ServerService.postActionToServer(
        { type: 'DELETE CREDIT NOTE BILL' },
        `credit_notes/bill/delete/${id}`,
      ),
  };
}
