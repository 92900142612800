import React from 'react';
import { Column, Row } from '../../easy_lib/Layout';
import { DataTable } from '../../components/DataTable/DataTable';
import { Button } from '../../easy_lib/Basics';
import { TicketBillingDataUpdater } from '../../components/TicketBillingData/TicketBillingDataUpdater';
import { Modal } from '../../easy_lib/Advanced/Modal';

const styles = {};

const keysToShow = [
  'date',
  'customerAccount',
  'name',
  'ticketNumber',
  'companyName',
  'trip',
  'total',
  'fees',
  {
    title: 'Numero de projet',
    value: item => item.customerMetaData && item.customerMetaData.projectNumber,
  },
  { title: 'BC', value: item => item.customerMetaData && item.customerMetaData.purchaseOrder },
  { title: 'Facture', value: item => item.billed && item.billed.bill_number },
];

export const TicketsComponent = ({
  tickets: ticketsFromProps,
  selectTicket,
  ticketsSelectedIds,
  accountsSelected,
  getTickets,
  addCustomerMetaData,
  children,
  goToBillCreation,
}) => {
  const [filterBilledTickets, setFilterBilledTickets] = React.useState(true);
  const [ticketToUpdate, setTicketToUpdate] = React.useState({});
  const [modalUpdateVisible, setModalUpdateVisible] = React.useState(false);
  const onItemRightClickAction = ticket => {
    setTicketToUpdate(ticket);
    setModalUpdateVisible(true);
  };

  const tickets = filterBilledTickets
    ? ticketsFromProps.filter(ticket => !ticket.billed)
    : ticketsFromProps;

  return (
    <Column>
      <DataTable
        items={tickets}
        keys={keysToShow}
        title={'Tickets Found'}
        onItemClick={selectTicket}
        itemStyle={item => (ticketsSelectedIds.includes(item._id) ? { fontWeight: 700 } : {})}
        onItemContextMenu={onItemRightClickAction}
        Header={() => (
          <Row>
            <Button onClick={getTickets}>Rafraichir billets</Button>
            <Button style={{ marginLeft: 10 }} onClick={() => selectTicket('reset')} danger>
              Reset selection
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              onClick={() => selectTicket(tickets.map(ticket => ticket._id))}
              success
            >
              Tout sélectionner
            </Button>
            <Button style={{ marginLeft: 10 }} onClick={goToBillCreation} warning>
              Creer facture
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              onClick={() => setFilterBilledTickets(!filterBilledTickets)}
              secondary={filterBilledTickets}
            >
              {filterBilledTickets ? 'Afficher' : 'Cacher'} billets déjà facturés
            </Button>
          </Row>
        )}
      />
      <Column />
      <Modal open={modalUpdateVisible} onClose={() => setModalUpdateVisible(false)}>
        <TicketBillingDataUpdater
          ticket={ticketToUpdate}
          onValidateAction={data => {
            console.log(data);
            addCustomerMetaData({ ticketId: ticketToUpdate._id, customerMetaData: data });
          }}
        />
      </Modal>
      {children}
    </Column>
  );
};
