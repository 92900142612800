import { combineReducers } from 'redux';
import { billsReducer } from './bills/reducer';
import { accountsReducer } from './accounts/reducer';
import { logInReducer } from './login/reducer';
import { datesReducer } from './dates/reducer';
import { ticketsReducer } from './tickets/reducer';
import { creditNotesReducer } from './creditNotes/reducer';
import { creditNoteBillReducer } from './creditNotesBill/creditNoteBillReducer';

export const rootReducer = combineReducers({
  bills: billsReducer,
  accounts: accountsReducer,
  login: logInReducer,
  dates: datesReducer,
  tickets: ticketsReducer,
  creditNotes: creditNotesReducer,
  creditNoteBills: creditNoteBillReducer,
});

export interface IRootReducer {
  bills: any;
  accounts: any;
  login: any;
  dates: any;
  tickets: { ticketsById: { [id: string]: any }; ticketsSelected: any[] };
  creditNotes: any;
  creditNoteBills: any;
}
