import { ServerService } from '../../server/ServerService';
import { ObjectID } from 'bson';
import { API_ACTIONS } from '../../server/API_ACTIONS';

const baseRoute = '/api';

export const getAccountsFromServer = () => {
  return ServerService.postToServer('', {
    _id: new ObjectID(),
    type: API_ACTIONS.ACCOUNTS.GET,
  })
    .then(r => r.data)
    .catch(e => {
      console.log('error during accounts retriecing process');
      return [];
    });
};
