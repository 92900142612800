import { Column } from '../../easy_lib/Layout';
import { generateLongDate } from '../../services/dateService';
import React from 'react';
import { numbToLetters } from '../../services/numbToLetters';
import {
  BillHeader,
  company,
  generateTicketNumberColumn,
  TVA_RATE,
  sortAlphabeticallyByField,
  billCreatorStyles,
  BillBottom,
} from '../createBill/billCreator';
import { adaptBillNumberFormat, addLeadingZero } from '../../services/numberService';
import { Table } from '../../easy_lib/Basics/Table';

export const creditNotesFunctions = {
  calculateTotals: tickets => {
    const totals = tickets.reduce(
      (final, ticket) => ({
        initialTicketAmount: final.initialTicketAmount + parseInt(ticket.initialTicketAmount),
        cancelationFees: final.cancelationFees + parseInt(ticket.cancelationFees),
        fees: final.fees + parseInt(ticket.agencyFees),
        netToRefund: final.netToRefund + parseInt(ticket.netToRefund),
        amountUsed: final.amountUsed + parseInt(ticket.amountUsed),
      }),
      { initialTicketAmount: 0, cancelationFees: 0, fees: 0, netToRefund: 0, amountUsed: 0 },
    );
    totals.TVA = Math.ceil(totals.fees * TVA_RATE);
    totals.netToRefundInLetters = numbToLetters(totals.netToRefund).toUpperCase();
    console.log(totals);

    return totals;
  },
};

export const CreditNoteBillCreator = ({
  creditNotes,
  customerAccount,
  className,
  remarks = '',
  paymentType,
  billNumber = '000',
  createdDate,
  withFS,
}) => {
  const [creationDate, setCreationDate] = React.useState(
    generateLongDate(createdDate || new Date()),
  );

  return (
    <Column className={className} style={{ margin: 10 }}>
      <BillHeader
        customerAccount={customerAccount}
        company={company}
        billNumber={billNumber}
        isCreditNote
      />
      <br />
      <BillMain
        tickets={creditNotes}
        date={creationDate}
        remarks={remarks}
        paymentType={paymentType}
        withFS={withFS}
      />
      <br />
      {/*<BillBottom date={creationDate} remarks={remarks} paymentType={paymentType} />*/}
    </Column>
  );
};

const BillMain = ({ tickets, date, remarks, paymentType, withFS }) => {
  let ticketIndex = 0;
  const generateDesignationColumn = ticket => (
    <td>
      <div style={{ fontWeight: 600 }}>{ticket.name}</div>
      <div style={billCreatorStyles.secondLine}>{ticket.trip}</div>
      <div style={billCreatorStyles.secondLine}>
        {ticket.flightDates &&
          ticket.flightDates.length > 0 &&
          `DEP: ${ticket.flightDates[0]} ${
            ticket.flightDates.length > 1 ? `RET: ${ticket.flightDates[1]}` : ''
          }`}
      </div>
    </td>
  );
  const generateCommandColumn = ({
    purchaseOrder,
    projectNumber,
    description,
    ticket_bill_number,
  }) => (
    <td style={{ width: 200, minWidth: 200, maxWidth: 200 }}>
      <div>{adaptBillNumberFormat(ticket_bill_number)}</div>
      <div style={{ fontWeight: 500, display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        BC{' '}
        <input
          type="text"
          style={{ fontWeight: 'inherit', marginLeft: 10, maxWidth: 100 }}
          defaultValue={purchaseOrder}
        />
      </div>
      <div style={billCreatorStyles.secondLine}>
        <input type="text" defaultValue={projectNumber} />
      </div>
      <div style={billCreatorStyles.secondLine}>
        <input type="text" defaultValue={description} />
      </div>
      {/*{description ? <div style={styles.secondLine}>{description}</div> : null}*/}
      {/*<div style={styles.secondLine}>{projectNumber}</div>*/}
    </td>
  );
  const generateIndexColumn = () => {
    ticketIndex += 1;
    return <td style={{ width: 30 }}>{addLeadingZero(ticketIndex)}</td>;
  };

  const generatePricesColumn = creditNote =>
    withFS
      ? [
          <td style={billCreatorStyles.price} key={'priceTTC'}>
            {parseInt(creditNote.initialTicketAmount).toLocaleString('fr')}
          </td>,
          <td style={{ ...billCreatorStyles.price }} key={'amountUsed'}>
            {creditNote.amountUsed.toLocaleString('fr')}
          </td>,
          <td style={billCreatorStyles.price} key={'fees'}>
            {parseInt(creditNote.cancelationFees).toLocaleString('fr')}
          </td>,
          <td style={{ ...billCreatorStyles.price }} key={'total'}>
            {creditNote.agencyFees.toLocaleString('fr')}
          </td>,
          <td style={{ ...billCreatorStyles.price, fontWeight: 600 }} key={'total'}>
            {creditNote.netToRefund.toLocaleString('fr')}
          </td>,
        ]
      : [
          <td style={billCreatorStyles.price} key={'priceTTC'}>
            {parseInt(creditNote.initialTicketAmount).toLocaleString('fr')}
          </td>,
          <td style={{ ...billCreatorStyles.price }} key={'amountUsed'}>
            {(creditNote.amountUsed + creditNote.agencyFees).toLocaleString('fr')}
          </td>,
          <td style={billCreatorStyles.price} key={'fees'}>
            {parseInt(creditNote.cancelationFees).toLocaleString('fr')}
          </td>,
          <td style={{ ...billCreatorStyles.price, fontWeight: 600 }} key={'total'}>
            {creditNote.netToRefund.toLocaleString('fr')}
          </td>,
        ];

  const generateTicketLine = (
    ticket,
    BCData = { purchaseOrder: '', projectNumber: '', description: '' },
  ) => (
    <tr key={ticket._id + JSON.stringify(ticket.customerMetaData)}>
      {generateIndexColumn()}
      {withFS &&
        generateCommandColumn({
          ticket_bill_number: ticket.ticket_bill_number,
          ...ticket.customerMetaData,
        })}
      {generateTicketNumberColumn(ticket)}
      {generateDesignationColumn(ticket)}
      {generatePricesColumn(ticket)}
    </tr>
  );

  const generateCreditNoteVerticalTotalsTable = totals => {
    return (
      <React.Fragment>
        {(withFS
          ? [
              { name: 'Montant total', value: totals.initialTicketAmount },
              { name: 'Total utilisé', value: totals.amountUsed },
              { name: 'Total pénalités', value: totals.cancelationFees },
              { name: 'Total FS', value: totals.fees },
              {
                name: 'Avoir hors TVA (FCFA)',
                value: totals.netToRefund,
                style: { fontWeight: 800 },
              },
              { name: 'TVA 19,25 % sur FS', value: totals.TVA },
              {
                name: 'Avoir (FCFA)',
                value: totals.netToRefund - totals.TVA,
                style: { fontWeight: 800 },
              },
            ]
          : [
              { name: 'Montant total', value: totals.initialTicketAmount },
              { name: 'Total utilisé', value: totals.amountUsed },
              { name: 'Total pénalités', value: totals.cancelationFees + totals.fees },
              { name: 'Avoir (FCFA)', value: totals.netToRefund, style: { fontWeight: 800 } },
            ]
        ).map(item => (
          <tr style={item.style} key={item.name}>
            <td colSpan={withFS ? '5' : '4'} style={{ border: 'none' }} />
            <td colSpan={withFS ? '3' : '2'}>{item.name}</td>
            <td style={{ width: '12vw', textAlign: 'right' }}>{item.value.toLocaleString('fr')}</td>
          </tr>
        ))}
      </React.Fragment>
    );
  };

  const generateCreditNoteTotalsTable = totals => {
    const valuesToShow = [
      { name: 'Avoir initial', value: totals.netToRefund },
      { name: 'Avoir imputé', value: 0 },
      { name: "Solde d'avoir", value: totals.netToRefund, style: { fontWeight: 800 } },
    ];

    return (
      <Table style={{ marginTop: 30, width: '100%', overflow: 'unset' }}>
        <tbody>
          <tr>
            {valuesToShow.map(item => (
              <th style={item.style}>{item.name}</th>
            ))}
          </tr>
          <tr style={{ textAlign: 'right' }}>
            {valuesToShow.map(item => (
              <td style={item.style}>{item.value.toLocaleString('fr')}</td>
            ))}
          </tr>
        </tbody>
      </Table>
    );
  };

  const totals = creditNotesFunctions.calculateTotals(tickets);

  const columnNames = withFS
    ? [
        '#',
        'Commande',
        'Numéro de billet',
        'Désignation',
        'Montant',
        'Utilisé',
        'PEN',
        'FS',
        'Avoir',
      ]
    : ['#', 'Numéro de billet', 'Désignation', 'Montant', 'Utilisé', 'PEN', 'Avoir'];

  return (
    <React.Fragment>
      <div>Yaoundé, le {date}</div>
      <Table style={{ verticalAlign: 'top', lineHeight: '20px', overflow: 'unset' }}>
        <thead>
          <tr>
            {columnNames.map(item => (
              <th key={item}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody style={{ verticalAlign: 'top' }}>
          {[...tickets].sort(sortAlphabeticallyByField('name')).map(generateTicketLine)}
          {/*{generateTotalDataLine(totals)}*/}
          {generateCreditNoteVerticalTotalsTable(totals)}
        </tbody>
      </Table>
      <div style={{ width: '65%', position: 'relative', top: '-140px' }}>
        {generateCreditNoteTotalsTable(totals)}
        <div style={{ fontWeight: 600, marginTop: 20 }}>
          Arrêté le présent avoir à la somme de {totals.netToRefundInLetters}
        </div>
        {withFS && <div># Sous réserve de fournir une attestation d'exonération de TVA #</div>}
        <BillBottom
          date={date}
          remarks={remarks}
          paymentType={paymentType}
          isCreditNote
          text={'La Comptabilité'}
        />
      </div>
    </React.Fragment>
  );
};

// const generateTotalDataLine = totals => {
//   const valuesToShow1 = [
//     { name: 'Montant total', value: totals.initialTicketAmount },
//     { name: 'Total utilisé', value: totals.amountUsed },
//     { name: 'Total pénalité ', value: totals.cancelationFees },
//     { name: 'Total FS', value: totals.fees },
//     { name: 'Avoir total', value: totals.netToRefund, style: { fontWeight: 800 } },
//   ];

//   return (
//     <React.Fragment>
//       <tr>
//         <th />
//       </tr>
//       <tr style={{ textAlign: 'right' }}>
//         <td colSpan="3" style={{ border: 'none' }} />
//         <td style={{ border: 'none', paddingRight: 20 }}>Total</td>
//         {valuesToShow1.map(item => (
//           <td style={item.style}>{item.value.toLocaleString('fr')}</td>
//         ))}
//       </tr>
//     </React.Fragment>
//   );
// };
