export const DATES_ACTIONS = {
  SET_DATES: 'SET_DATES',
};

export const datesActionCreator = {
  SET_DATES: (payload: { before: string; after: string }) => ({
    type: DATES_ACTIONS.SET_DATES,
    payload,
  }),
};
