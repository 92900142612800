const sortByNameAndTicketNumber = (a, b) =>
  a['name'] + a['ticketNumber'] > b['name'] + b['ticketNumber'] ? 1 : -1;

export const sortByBCAndNameAndTicketNumber = (a, b) => {
  const customerDataA = a.customerMetaData;
  const customerDataB = b.customerMetaData;
  console.log(customerDataA, customerDataB);

  if (!customerDataA && customerDataB) {
    return 1;
  }
  if (customerDataA && !customerDataB) {
    return -1;
  }
  if (customerDataA && customerDataB) {
    if (customerDataA.purchaseOrder !== customerDataB.purchaseOrder) {
      return (customerDataA.purchaseOrder || '') > (customerDataB.purchaseOrder || '') ? 1 : -1;
    } else {
      return sortByNameAndTicketNumber(a, b);
    }
  }
  return sortByNameAndTicketNumber(a, b);
};
