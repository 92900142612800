import { keyBy, mapValues } from 'lodash';
import { DATES_ACTIONS } from './actions';

export const getInitialState = (date = new Date()) => ({
  before: date.toISOString().slice(0, 10),
  after: new Date(date.getTime() - 60 * 60 * 24 * 30 * 1000).toISOString().slice(0, 10),
});

export const datesReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case DATES_ACTIONS.SET_DATES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
