import React from 'react';
import { theme } from '../../theme';

export const Select = ({ data = [], renderItem, value, ...props }) => {
  return (
    <select name={props.name || ''} value={value} style={{ ...theme.styles.input, ...props.style }} onChange={e => props.onChange(e)}>
      <option value="-1" defaultValue>
        {props.emptyValue || ' --- make a choice ---'}
      </option>

      {data.map((item, key) => {
        const { value, display } = renderItem ? renderItem(item) : { value: item, display: item };
        return (
          <option key={key} value={value}>
            {display}
          </option>
        );
      })}
    </select>
  );
};
