import { theme } from '../../theme';
import React from 'react';

const styles = {
  modalContainer: transparent => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: !transparent && theme.colorUsages.modalBackground,
    color: theme.colorUsages.textColor,
    zIndex: 100,
    padding: 30
  }),
  modalBox: {
    backgroundColor: theme.colorUsages.white,
    padding: 25,
    outline: 'none',
    borderRadius: 5,
    width: 'fit-content',
    maxHeight: '100%',
    overflow: 'scroll',
    margin: '10%',
  },
};

export const Modal = ({ children, onClose, style, transparent, open }) =>
  open ? (
    <div style={styles.modalContainer(transparent)} onClick={onClose}>
      <div style={{ ...styles.modalBox, ...style }} onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  ) : null;
