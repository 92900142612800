import { connect } from 'react-redux';
import { accountsActionCreator } from '../../modules/accounts/actions';
import { getAccounts, getAccountsSelected } from '../../modules/accounts/selector';
import { AccountsComponent } from './accounts.component';
import { getDates } from '../../modules/dates/selector';

const mapStateToProps = state => {
  return {
    accounts: getAccounts(state),
    accountsSelected: getAccountsSelected(state),
    dates: getDates(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAccounts: () => {
      dispatch(accountsActionCreator.GET_ACCOUNTS_REQUEST());
    },
    selectAccount: account => {
      dispatch(accountsActionCreator.SELECT_ACCOUNT(account));
    },
  };
};

export const Accounts = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountsComponent);
