import React from 'react';
import { Connexion } from '../../components/Connexion';
import { NavLink } from 'react-router-dom';
import {
  airReaderRoutesArray,
  airReaderRoutesArrayGroupedByCategory,
} from '../../services/routesService';
import { theme } from '../../theme.js';
import { Column } from './Column';
import { Row } from './Row';

const drawerWidth = 200;

const styles = {
  layoutContainer: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    justifyContent: 'space-between',
    backgroundColor: theme.colorUsages.primary,
    color: theme.colorUsages.white,
    padding: `${theme.getSpacing(1)}px ${theme.getSpacing(3)}px`,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    overflow: 'hidden',
    borderRight: `1px solid #efefef`,
    display: 'flex',
    flexDirection: 'column',
  },
  drawerClose: {
    width: theme.getSpacing(7) + 1,
  },
  main: {
    flex: 1,
    padding: theme.getSpacing(6),
    width: `calc(100% - ${drawerWidth}px)`,
    // position: 'relative',
    // overflow: 'scroll',
  },
  activeLink: {
    fontWeight: 700,
    color: theme.colorUsages.primary,
  },
  menuSection: {
    borderBottom: `1px solid #efefef`,
  },
  menuSectionTitle: {
    height: theme.getSpacing(8),
    overflow: 'hidden',
    borderBottom: `1px solid #efefef`,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontWeight: 800,
    color: 'black',
    padding: `${theme.getSpacing(1)}px ${theme.getSpacing(4)}px`,
  },
  menuItem: {
    height: theme.getSpacing(8),
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'black',
    padding: `${theme.getSpacing(1)}px ${theme.getSpacing(0)}px`,
    marginLeft: `${theme.getSpacing(6)}px`,
  },
};

export const Layout = props => {
  const { children } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Column style={styles.layoutContainer}>
      <Row style={styles.appBar}>
        <span onClick={() => setOpen(!open)}>Icon to open</span>
        <span>Agence Française de Voyages</span>
        <Connexion />
      </Row>
      <Row style={{ flex: 1, alignItems: 'stretch', flexWrap: 'nowrap' }}>
        <Nav open={open} />
        <main style={styles.main} id="main">
          {children}
        </main>
      </Row>
    </Column>
  );
};

const MenuItem = ({ to, text }) => (
  <NavLink exact key={text} style={styles.menuItem} activeStyle={styles.activeLink} to={to}>
    {text}
  </NavLink>
);

const MenuSection = ({ items = [], text }) => {
  const [isMenuSectionOpened, setMenuSectionOpened] = React.useState(true);
  return (
    <div onClick={() => setMenuSectionOpened(!isMenuSectionOpened)} style={styles.menuSection}>
      <div style={styles.menuSectionTitle}> {text}</div>
      <div className={isMenuSectionOpened ? '' : 'display-none'} onClick={e => e.stopPropagation()}>
        {items.map(item => (
          <MenuItem {...item} />
        ))}
      </div>
    </div>
  );
};

const Nav = ({ open = true }) => {
  let finalItems = [];
  for (let menuItem of airReaderRoutesArrayGroupedByCategory) {
    if (menuItem.type === 'section') {
      finalItems.push(<MenuSection {...menuItem} key={menuItem.text} />);
    } else {
      finalItems.push(<MenuItem {...menuItem} key={menuItem.text} />);
    }
  }
  return <nav style={{ ...styles.drawer, ...(open ? styles.drawerClose : {}) }}>{finalItems}</nav>;
};
