import React from 'react';
import { ObjectID } from 'bson';
import { FormGenerator } from '../../easy_lib/Advanced';
import { Column, Row } from '../../easy_lib/Layout';
import {
  LOCAL_STORAGE_KEYS,
  LocalStorageItemSelector,
  localStorageService,
} from '../../services/localStorageService/localStorageService';

const fieldsToAdd = ['projectNumber', 'purchaseOrder', 'description'];

const TEXT_CONSTS = {
  NEW_GROUP: 'NEW GROUP',
  ADD_BILL_META_DATA: 'Ajouter meta data',
};

const ticketGroupField = [
  {
    name: 'projectNumber',
    type: 'text',
    label: 'Numero de projet',
    placeholder: 'Numero de projet',
    required: true,
  },
  {
    name: 'purchaseOrder',
    type: 'text',
    label: 'Bon de commande',
    placeholder: 'Bon de commande',
    required: true,
  },
  {
    name: 'description',
    type: 'text',
    label: 'Description',
    placeholder: 'Description de la section',
  },
];

const styles = {
  flex1: { flex: 1 },
  label: { fontWeight: 'bold', marginTop: 10, marginBottom: 5 },
};

export const TicketBillingDataUpdater = ({ ticket, onValidateAction = console.log }) => {
  const [state, setState] = React.useState(0);
  const [initialFormData, setInitialFormData] = React.useState(
    ticket.customerMetaData || localStorageService.getLastItem(LOCAL_STORAGE_KEYS.LAST_BILL_DATA) || {}
  );
  React.useEffect(() => ticket.customerMetaData && setInitialFormData(ticket.customerMetaData), [ticket]);

  return (
    <Row>
      <div style={{ marginRight: 20 }}>
        {['ticketNumber', 'name', 'accountName', 'customerAccount'].map(item => (
          <Column>
            <label style={styles.label}>{item}</label>
            <div>{ticket[item]}</div>
          </Column>
        ))}
      </div>
      <FormGenerator
        items={ticketGroupField}
        title={TEXT_CONSTS.ADD_BILL_META_DATA}
        initialData={initialFormData}
        onValidateText={TEXT_CONSTS.ADD_BILL_META_DATA}
        onValidateAction={data => {
          onValidateAction(data);
          localStorageService.storeItem(LOCAL_STORAGE_KEYS.LAST_BILL_DATA, data);
          setState(state + 1);
        }}
      />
      <LocalStorageItemSelector
        localStorageKey={LOCAL_STORAGE_KEYS.LAST_BILL_DATA}
        onItemClick={setInitialFormData}
      />
    </Row>
  );
};
