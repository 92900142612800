import { fork } from 'redux-saga/effects';
import { watchBillsActionsSync } from './bills/sagas';
import { watchAccountsActionsSync } from './accounts/sagas';
import { watchLogInActionsSync } from './login/sagas';
import { watchTicketsActionsSync } from './tickets/sagas';

export function* rootSaga() {
  yield fork(watchBillsActionsSync);
  yield fork(watchAccountsActionsSync);
  yield fork(watchLogInActionsSync);
  yield fork(watchTicketsActionsSync);
}
