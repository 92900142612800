import { connect } from 'react-redux';
import { billsActionCreator } from '../../modules/bills/actions';
import { getAccountsById, getAccountsSelected } from '../../modules/accounts/selector';
import { getBills, getBillSelected } from '../../modules/bills/selector';
import { BillsComponent } from './bills.component';
import { getDates } from '../../modules/dates/selector';
import { ServerService } from '../../server/ServerService';
import { ACTIONS_GENERATOR } from '../../server/API_ACTIONS';
import { airReaderRoutes } from '../../services/routesService';

const mapStateToProps = state => {
  return {
    bills: getBills(state).filter(bill => !bill.canceled),
    accountsById: getAccountsById(state),
    accountsSelected: getAccountsSelected(state),
    billSelected: getBillSelected(state),
    dates: getDates(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getBills: () => {
      dispatch(billsActionCreator.GET_BILLS_REQUEST());
    },
    selectBill: bill => {
      dispatch(billsActionCreator.SELECT_BILL(bill));
    },
    updateBill: async bill => {
      const requestAnswer = await ServerService.postActionToServer(
        ACTIONS_GENERATOR.TICKETS.GET_BY_IDS(bill.tickets.map(t => t[0]))
      );
      const ticketsFromBill = requestAnswer.data;
      dispatch(ACTIONS_GENERATOR.TICKETS.GET_SUCCESS(ticketsFromBill));
      dispatch(ACTIONS_GENERATOR.TICKETS.SELECT_TICKET(ticketsFromBill.map(t => t._id)));
      console.log(bill);
      ownProps.history.push(airReaderRoutes.BILLS_UPDATE.to, bill);
    },
  };
};

export const Bills = connect(
  mapStateToProps,
  mapDispatchToProps
)(BillsComponent);
