import React from 'react';

const styles = {
  accountSelectedDiv: {
    maxHeight: 50,
    overflow: 'scroll',
    display: 'flex',
    flexWrap: 'wrap',
  },
  titleDiv: {
    marginBottom: 10,
    fontWeight: 600,
  },
  cardStyle: {
    backgroundColor: '#f4f4f4',
    borderRadius: 3,
  },
};

export const SelectionSumUp = ({ items, renderItem, field, style, title, ...props }) => (
  <div style={{ ...style }} className={'card'} {...props}>
    <div style={styles.titleDiv}>{title}</div>
    <div style={styles.accountSelectedDiv}>
      {items.map(item =>
        renderItem ? (
          renderItem(item)
        ) : (
          <span style={{ marginLeft: 5 }}>{(field ? item[field] : item) + ' / '}</span>
        )
      )}
    </div>
  </div>
);

export const DataSelector = ({ items, renderItem, initialSelection, title, onValidate }) => {
  const [selection, setSelection] = React.useState(initialSelection);
  return (
    <div>
      <button onClick={() => onValidate(selection)}>Valider</button>
      <div style={styles.titleDiv}>{title}</div>
      {items.map((item, key) => (
        <div
          style={{ fontWeight: selection.includes(item) && '600' }}
          onClick={() => setSelection(toggleFromArray(selection, item))}
        >
          {renderItem ? renderItem(item) : item}
        </div>
      ))}
    </div>
  );
};

export const DataSelectorWithOrder = ({
  items,
  renderItem,
  initialSelection,
  title,
  onValidate,
}) => {
  const [selection, setSelection] = React.useState(initialSelection);
  const [itemDragged, setItemDragged] = React.useState('');

  const handleDrop = itemWhereItWasDropped => {
    if (itemDragged === itemWhereItWasDropped) {
      return;
    }
    const indexOfItemDragged = selection.findIndex(item => item === itemDragged);
    const indexOfItemDropped = selection.findIndex(item => item === itemWhereItWasDropped);
    if (indexOfItemDragged === -1 || indexOfItemDropped === -1) {
      return;
    }
    const duplicateSelection = [...selection];
    duplicateSelection.splice(indexOfItemDragged, 1);
    duplicateSelection.splice(indexOfItemDropped, 0, itemDragged);
    setSelection(duplicateSelection);
  };

  return (
    <div>
      <button onClick={() => onValidate(selection)}>Valider</button>
      <button
        onClick={() =>
          setSelection([...selection, ...items.filter(item => !selection.includes(item))])
        }
      >
        Tout sélectionner
      </button>
      <button onClick={() => setSelection([])}>Tout déselectionner</button>
      <div style={styles.titleDiv}>{title}</div>
      <div style={{ maxWidth: 300 }}>
        Hello Raoul ! tu peux déplacer les ligne de la colonne de droite pour les mettre dans
        l'ordre que tu souhaites :-) N'oublie pas de cliquer sur valider pour enregistrer tes
        résultats
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ padding: 10 }}>
          <div style={{ marginBottom: 10 }}>Initial</div>
          {items
            .filter(item => !selection.includes(item))
            .map((item, key) => (
              <div onClick={() => setSelection(upsertInArray(selection, item))}>
                {renderItem ? renderItem(item) : item}
              </div>
            ))}
        </div>
        <div
          style={{ padding: 10, display: 'flex', flexDirection: 'column' }}
          onDragOver={e => e.preventDefault()}
          onDrop={e => handleDrop(selection && selection[0])}
        >
          <div style={{ marginBottom: 10 }}>Selectionnés</div>
          {selection.map((item, key) => (
            <div
              draggable
              style={{ fontWeight: selection.includes(item) && '600' }}
              onDragStart={e => setItemDragged(item)}
              onDragOver={e => e.preventDefault()}
              onDrop={e => {
                handleDrop(item);
                e.stopPropagation();
              }}
            >
              {renderItem ? renderItem(item) : item}
              <span
                style={{ paddingLeft: 5 }}
                onClick={() => setSelection(removeFromArray(selection, item))}
              >
                X
              </span>
            </div>
          ))}
          <div
            style={{ flex: 1, minHeight: 20 }}
            onDragOver={e => e.preventDefault()}
            onDrop={e => {
              handleDrop(selection && selection[selection.length - 1]);
              e.stopPropagation();
            }}
          />
        </div>
      </div>
    </div>
  );
};

const toggleFromArray = (array, item, comparisonFunction) => {
  const finalArray = [...array];
  const itemToToggle = item;
  const itemIndexInArray = comparisonFunction
    ? finalArray.findIndex(comparisonFunction)
    : finalArray.indexOf(item);
  if (itemIndexInArray > -1) {
    finalArray.splice(itemIndexInArray, 1);
  } else {
    finalArray.push(itemToToggle);
  }
  return finalArray;
};

const removeFromArray = (array, item, comparisonFunction) => {
  const finalArray = [...array];
  const itemIndexInArray = comparisonFunction
    ? finalArray.findIndex(comparisonFunction)
    : finalArray.indexOf(item);
  if (itemIndexInArray > -1) {
    finalArray.splice(itemIndexInArray, 1);
  }
  return finalArray;
};

const upsertInArray = (array, item, comparisonFunction) => {
  const finalArray = [...array];
  const itemIndexInArray = comparisonFunction
    ? finalArray.findIndex(comparisonFunction)
    : finalArray.indexOf(item);
  if (itemIndexInArray === -1) {
    finalArray.push(item);
  }
  return finalArray;
};
