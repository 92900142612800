export const getFlightDates = ticket => {
  if (!ticket['flightDates']) {
    return [];
  }
  if (typeof ticket['flightDates'] === 'string') {
    return ticket['flightDates'].split(',').map(date => date.trim());
  }
  if (Array.isArray(ticket['flightDates'])) {
    return ticket['flightDates'];
  }
  return [];
};
