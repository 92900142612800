import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { ServerService } from '../../server/ServerService';
import { CreditNoteBillCreator } from './creditNoteBillCreator';
import { ButtonWithModal } from '../createBill/tokeep';

export const CreditNoteBillViewer = ({ match, ...props }) => {
  const creditNoteId = match.params.id;
  const dispatch = useDispatch();
  React.useEffect(() => {
    const fetchBills = async () => {
      const creditNoteBill = await ServerService.CREDIT_NOTE.GET_BILL(creditNoteId).then(
        res => res.data,
      );
      dispatch({ type: 'CREDIT_NOTE_BILLS/ADD', bills: [creditNoteBill] });
    };
    fetchBills();
  }, []);
  const creditNoteBill = useSelector(state => state.creditNoteBills.billsById[creditNoteId]);
  const creditNoteIdsOnBill =
    creditNoteBill && creditNoteBill.creditNotes
      ? creditNoteBill.creditNotes.map(creditNote => creditNote._id)
      : [];
  const creditNotesToShow = useSelector(state =>
    creditNoteIdsOnBill.map(creditNoteId => state.tickets.ticketsById[creditNoteId]),
  ).filter(creditNote => creditNote);
  const creditNotes = useSelector(state => state.tickets.ticketsById);
  const accountsSelected = useSelector(state => state.accounts.accountsSelected);
  const [billInDeletion, setBillInDeletion] = React.useState(false);
  const [withFSCheckbox, setWithFSCheckboxValue] = React.useState(true);

  console.log(match, props);
  const deleteBill = async () => {
    setBillInDeletion(true);
    return await ServerService.CREDIT_NOTE.DELETE_BILL(creditNoteId).then(result => {
      alert('Facture avoir supprimée');
      props.history.push('/credit_notes/bills');
    });
  };

  return (
    <div>
      <div className="buttons">
        {creditNoteBill.canceled ? (
          <div className="subtitle has-text-black has-text-weight-bold has-text-centered">
            Facture annulée
          </div>
        ) : (
          <ButtonWithModal value="SUPPRIMER LA FACTURE" className="button is-danger">
            {closeModal => (
              <div>
                <h3 className="title">Supprimer cette facture ?</h3>
                <div className="buttons is-right">
                  <button
                    className={['button is-danger', billInDeletion && 'is-loading'].join(' ')}
                    onClick={() =>
                      deleteBill().finally(() => {
                        setBillInDeletion(false);
                        closeModal();
                      })
                    }
                  >
                    Oui
                  </button>
                  <button onClick={closeModal} className="button is-info">
                    Non
                  </button>
                </div>
              </div>
            )}
          </ButtonWithModal>
        )}
        <div style={{ marginLeft: 40 }}>
          <input
            type="checkbox"
            checked={withFSCheckbox}
            onChange={e => setWithFSCheckboxValue(e.target.checked)}
          />
          <label htmlFor="">Afficher les FS ? </label>
        </div>
      </div>
      {creditNotesToShow ? (
        <CreditNoteBillCreator
          billNumber={creditNoteBill.billNumber}
          remarks={creditNoteBill.remarks || ''}
          creditNotes={creditNotesToShow.map(
            ({ creditNoteMetaData, _id, ticketNumber, name, customerMetaData }) => ({
              ...creditNoteMetaData,
              _id,
              ticketNumber,
              name,
              customerMetaData,
            }),
          )}
          createdDate={creditNoteBill.creationDate}
          className={'bill_preview'}
          customerAccount={accountsSelected[0]}
          withFS={withFSCheckbox}
        />
      ) : (
        <div>Pas de credit note trouvée</div>
      )}
    </div>
  );
};
