import {
  ADD_BILL_PAYMENT_SUCCESS,
  CREATE_BILL_SUCCESS,
  GET_BILLS_SUCCESS,
  IBillsActions,
  SELECT_BILL,
  SELECT_MULTI_BILL,
} from './actions';
import { LOCAL_ACTIONS } from '../../server/API_ACTIONS';

export const initialState: { bills: any[]; billSelected: { _id?: string }; billsSelected: any[] } = {
  bills: [],
  billSelected: {},
  billsSelected: [],
};

export const billsReducer = (state = initialState, action: IBillsActions) => {
  switch (action.type) {
    case GET_BILLS_SUCCESS:
      return {
        ...state,
        bills: action.payload.bills,
      };
    case SELECT_BILL:
      return {
        ...state,
        billSelected: action.payload.bill,
      };
    case SELECT_MULTI_BILL:
      console.log(state);
      const billsSelected = [...state.billsSelected];
      const billClicked = action.payload;
      const billClickedIndexInBillsSelected = state.billsSelected.findIndex(
          bill => bill._id === billClicked._id
      );
      if (billClickedIndexInBillsSelected > -1) {
        billsSelected.splice(billClickedIndexInBillsSelected, 1);
      } else {
        billsSelected.push(billClicked);
      }
      console.log('accountsSelected', billsSelected);
      return {
        ...state,
        billsSelected,
      };
    case ADD_BILL_PAYMENT_SUCCESS: {
      const billToUpdateIndex = state.bills.findIndex(bill => bill._id === state.billSelected._id);
      const billWithPaymentDetailsAdded = { ...state.billSelected, payment: action.payload };
      const newBillsArray = [...state.bills].splice(billToUpdateIndex, 1, {
        ...state.billSelected,
        payment: action.payload,
      });
      return {
        ...state,
        bills: newBillsArray,
        billSelected: billWithPaymentDetailsAdded,
      };
    }
    case LOCAL_ACTIONS.BILLS.CREATE_SUCCESS:{
      //@ts-ignore
      const billToSave =  action.payload;
      const indexFound = state.bills.findIndex(bill => bill._id ===billToSave._id)
      if (indexFound > -1) {
        console.log('bill found');
        const newBills = [...state.bills]
        newBills.splice(indexFound, 1, billToSave);
        return {...state, bills: newBills}
      }else {
        return {...state, bills: [...state.bills, billToSave]};
      }
    }  
    default:
      return state;
  }
};
