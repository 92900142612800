import React, { useState } from 'react';
import { Button } from '../../easy_lib/Basics';
import { FormGenerator, Modal } from '../../easy_lib/Advanced';
import { showConnexionModalSubject } from '../../server/axiosInstance';

export class ConnexionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modalOpen: false, initialCredentials: { name: '', password: '' } };
    showConnexionModalSubject.subscribe(name => {
      this.setState({ initialCredentials: { name, password: '' } });
      this.openModal();
    });
  }

  openModal = () => this.setState({ modalOpen: true });
  closeModal = () => this.setState({ modalOpen: false });

  render() {
    const props = this.props;
    const { modalOpen, initialCredentials } = this.state;

    return (
      <LogInButtonWithModal
        onClick={props.isLogged ? props.logOut : this.openModal}
        text={
          props.isLogged ? (
            <div>
              <span>{props.user.name}</span>
              <div>{TEXT_CONSTS.LOG_OUT}</div>
            </div>
          ) : (
            TEXT_CONSTS.LOG_IN
          )
        }
      >
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={modalOpen}
          onClose={this.closeModal}
        >
          <FormGenerator
            items={connexionItems}
            title={TEXT_CONSTS.CONNEXION}
            initialData={initialCredentials}
            onValidateAction={data => {
              props.tryToLogIn(data);
              this.closeModal();
            }}
            onValidateText={TEXT_CONSTS.CONNEXION}
          />
        </Modal>
      </LogInButtonWithModal>
    );
  }
}

const TEXT_CONSTS = {
  CONNEXION: 'CONNEXION',
  LOG_OUT: 'SE DECONNECTER',
  LOG_IN: 'SE CONNECTER',
};

const connexionItems = [
  {
    name: 'name',
    type: 'text',
    label: 'Username',
    placeholder: 'Username',
    autoComplete: 'name',
  },
  {
    name: 'password',
    type: 'password',
    label: 'Password',
    placeholder: 'Password',
    autoComplete: 'current-password',
  },
];

const LogInButtonWithModal = ({ onClick, children, text }) => {
  return (
    <div>
      <Button color="inherit" onClick={onClick} style={{padding: 0}}>
        {text}
      </Button>
      {children}
    </div>
  );
};
