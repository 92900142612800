import { ServerService } from '../../server/ServerService';
import { API_ACTIONS } from '../../server/API_ACTIONS';
import { ObjectID } from 'bson';

export const logInRequest = userData => {
  return ServerService.postToServer('/authentication', {
    _id: new ObjectID(),
    type: API_ACTIONS.AUTHENTICATION.LOGIN,
    payload: userData,
  }).then(response => response.data);
};
