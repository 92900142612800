import { connect } from 'react-redux';
import { billsActionCreator } from '../../../modules/bills/actions';
import { getAccountsById, getAccountsSelected } from '../../../modules/accounts/selector';
import { getBills, getBillsSelected } from '../../../modules/bills/selector';
import { getDates } from '../../../modules/dates/selector';
import { ticketsActionCreator } from '../../../modules/tickets/actions';
import {
  getTickets,
  getTicketsSelected,
  getTicketsSelectedIds,
} from '../../../modules/tickets/selector';
import { ServerService } from '../../../server/ServerService';
import { API_ACTIONS, ACTIONS_GENERATOR } from '../../../server/API_ACTIONS';
import { BillsUpdaterComponent } from './billsUpdater.component';

const mapStateToProps = state => {
  return {
    ticketsSelected: getTicketsSelected(state),
    accountsById: getAccountsById(state),
    accountsSelected: getAccountsSelected(state),
    billsSelected: getBillsSelected(state),
    dates: getDates(state),
    ticketsSelectedIds: getTicketsSelectedIds(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectTicket: ticket => {
      dispatch(ticketsActionCreator.SELECT_TICKET(ticket));
    },
    createBill: async (data: any, tickets: any[]) => {
      const action = billsActionCreator.CREATE_BILL(data);
      await dispatch(action);
      try {
        const result = await ServerService.postActionToServer(action);
        const actionSuccess = billsActionCreator.CREATE_BILL_SUCCESS({
          ...result.data,
        });
        const {bill_number, bill_id} = result.data;
        dispatch(
          ACTIONS_GENERATOR.TICKETS.GET_SUCCESS(
            tickets.map(ticket => ({ ...ticket, billed: result.data }))
          )
        );
        dispatch(ACTIONS_GENERATOR.BILLS.CREATE_SUCCESS({...data, billNumber: bill_number, _id: bill_id}))
        alert('billet enregistre');
      } catch (e) {
        alert('error when creating bill');
        alert(e);
      }
    },
    addCustomerMetaData: async ({ ticketId, customerMetaData }) => {
      const action = {
        type: API_ACTIONS.TICKETS.ADD_CUSTOMER_META_DATA,
        payload: { ticketId, customerMetaData },
      };
      await dispatch(action);
      try {
        const result = await ServerService.postActionToServer(action);
        // const actionSuccess =
        dispatch({ type: API_ACTIONS.TICKETS.GET_SUCCESS, payload: [result.data] });
        console.log(result.data);
      } catch (e) {
        console.log(e);
      }
    },
  };
};

export const BillsUpdater = connect(
  mapStateToProps,
  mapDispatchToProps
)(BillsUpdaterComponent);
