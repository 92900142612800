import { connect } from 'react-redux';
import { getAccountById, getAccountsSelected } from '../../modules/accounts/selector';
import { getBillSelected } from '../../modules/bills/selector';
import { BillPayerManagerComponent } from './billPayerManager.component';
import { billsActionCreator } from '../../modules/bills/actions';

const mapStateToProps = state => {
  return {
    accountsSelected: getAccountsSelected(state),
    billSelected: getBillSelected(state),
    account: getAccountById(state, getBillSelected(state).customerAccount),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addPayment: paymentDetails => {
      dispatch(billsActionCreator.ADD_BILL_PAYMENT_REQUEST(paymentDetails));
    },
  };
};

export const BillPayerManager = connect(
  mapStateToProps,
  mapDispatchToProps
)(BillPayerManagerComponent);
