import React from 'react';
import { Column, Row } from '../../easy_lib/Layout';
import { DataTable } from '../../components/DataTable/DataTable';
import { Button } from '../../easy_lib/Basics';
import { CreditNoteBillCreator } from './creditNoteBillCreator';
import { InputHandler, Modal } from '../../easy_lib/Advanced';
import { ACTIONS_GENERATOR } from '../../server/API_ACTIONS';
import { ServerService } from '../../server/ServerService';
import { useDispatch } from 'react-redux';

const keysToShow = [
  'customerAccount',
  'name',
  'ticketNumber',
  'companyName',
  'trip',
  {
    title: 'Prix initial billet',
    value: item => item.creditNoteMetaData && item.creditNoteMetaData.initialTicketAmount,
  },
  {
    title: 'Frais annulation',
    value: item => item.creditNoteMetaData && item.creditNoteMetaData.cancelationFees,
  },
  {
    title: 'Frais agence',
    value: item => item.creditNoteMetaData && item.creditNoteMetaData.agencyFees,
  },
  {
    title: 'Net à remb.',
    value: item => item.creditNoteMetaData && item.creditNoteMetaData.netToRefund,
  },
  {
    title: 'Numero de projet',
    value: item => item.customerMetaData && item.customerMetaData.projectNumber,
  },
  { title: 'BC', value: item => item.customerMetaData && item.customerMetaData.purchaseOrder },
  { title: 'Facture', value: item => item.billed && item.billed.bill_number },
];

const ITEM_ACTIONS = [
  {
    name: 'TROUVER BC DATA',
    action: item => {
      console.log('je trouve les BC datas', item);
      return ServerService.postActionToServer(
        ACTIONS_GENERATOR.CREDIT_NOTE.GET_BC_DATA({
          _id: item._id,
          ticketNumber: item.ticketNumber,
        }),
      ).then(res => res.data);
    },
  },
];

export const CreditNotesComponent = ({ history, ...props }) => {
  const [remarks, setRemarks] = React.useState('');
  const [billNumber, setBillNumber] = React.useState('');
  const [itemActionData, setItemActionData] = React.useState({});
  const [contextMenuData, setContextMenuData] = React.useState({
    visible: false,
    position: { x: 0, y: 0 },
  });
  const [withFSCheckbox, setWithFSCheckboxValue] = React.useState(true);
  const dispatch = useDispatch();

  const showItemsActions = (item, e) => {
    setItemActionData(item);
    console.log(e);
    setContextMenuData({ position: { x: e.clientX, y: e.clientY }, visible: true });
  };

  const {
    creditNotes,
    selectCreditNote,
    creditNotesSelectedId,
    dates,
    getCreditNotes,
    updateCreditNotes,
    children,
    bills,
    accountsSelected,
  } = props;
  console.log(
    'selected creditNotes :',
    creditNotesSelectedId,
    creditNotes,
    accountsSelected[0],
    bills,
  );

  return (
    <Column onClick={() => setContextMenuData({ position: {}, visible: false })}>
      <Modal
        open={contextMenuData.visible}
        transparent
        style={{
          // display: contextMenuData.visible ? '' : 'none',
          position: 'fixed',
          top: contextMenuData.position.y,
          left: contextMenuData.position.x,
          padding: 0,
          borderRadius: 0,
          margin: 0,
        }}
      >
        <div style={{ backgroundColor: 'white', padding: 20, border: '1px solid grey' }}>
          {ITEM_ACTIONS.map(action => (
            <div
              className="hoverable"
              key={action.name}
              onClick={() =>
                action
                  .action(itemActionData)
                  .then(updateCreditNotes)
                  .then(setContextMenuData({ position: {}, visible: false }))
              }
            >
              {action.name}
            </div>
          ))}
        </div>
      </Modal>

      <InputHandler
        row
        type="textarea"
        onChange={e => setRemarks(e.target.value)}
        label={'Remarks'}
        value={remarks}
        style={{ flex: 1, minHeight: 100, marginBottom: 10 }}
      />
      <DataTable
        items={creditNotes}
        keys={keysToShow}
        title={'Avoirs trouvés'}
        onItemContextMenu={showItemsActions}
        onItemClick={selectCreditNote}
        isBilledCheck={ticket => ticket.creditNoteBillNumber}
        itemStyle={item => (creditNotesSelectedId.includes(item._id) ? { fontWeight: 700 } : {})}
        Header={() => (
          <Row className="buttons">
            <button
              className="button is-link"
              onClick={() => getCreditNotes(accountsSelected.map(account => account._id), dates)}
            >
              Refresh tickets
            </button>
            <button className="button is-danger" onClick={() => selectCreditNote('reset')}>
              Reset selection
            </button>
            <button
              onClick={() => selectCreditNote(creditNotes.map(ticket => ticket._id))}
              className="button is-success"
            >
              Select all
            </button>
          </Row>
        )}
      />
      <Column />
      {children}

      <div className="section columns">
        <input
          placeholder="Numero de facture ? "
          className="input column is-6"
          onChange={e => setBillNumber(e.target.value)}
          title="Si vide, le numero se met à jour tout seul"
        />
        <button
          style={{ marginLeft: 10 }}
          onClick={async () =>
            await ServerService.CREDIT_NOTE.CREATE_BILL({
              tickets: creditNotes.filter(x => x && creditNotesSelectedId.includes(x._id)),
              customerAccount: accountsSelected[0],
              billNumber,
              remarks,
            }).then(res => {
              const billCreated = res.data;
              dispatch({ type: 'CREDIT_NOTE_BILLS/ADD', bills: [billCreated] });
              history.push('/credit_notes/bills/');
              history.push(`/credit_notes/bills/${billCreated._id}`);
            })
          }
          className="button is-warning"
        >
          Creer facture
        </button>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
          fontSize: '24px',
        }}
      >
        <input
          type="checkbox"
          checked={withFSCheckbox}
          onChange={e => setWithFSCheckboxValue(e.target.checked)}
        />
        <label htmlFor="">Afficher les FS ? </label>
      </div>
      <CreditNoteBillCreator
        remarks={remarks}
        creditNotes={creditNotes
          .map(({ creditNoteMetaData, _id, ticketNumber, name, customerMetaData }) => ({
            ...creditNoteMetaData,
            _id,
            ticketNumber,
            name,
            customerMetaData,
          }))
          .filter(x => x && creditNotesSelectedId.includes(x._id))}
        className={'bill_preview'}
        withFS={withFSCheckbox}
        customerAccount={accountsSelected[0]}
      />
    </Column>
  );
};
