import { CreditNoteCreateComponent } from './CreditNoteCreate.component';
import { connect } from 'react-redux';
import { getTickets } from '../../modules/tickets/selector';

const mapStateToProps = state => {
  return {
    tickets: getTickets(state).filter(ticket => !ticket.canceled),
  };
};

export const CreditNoteCreate = connect(mapStateToProps)(CreditNoteCreateComponent);
