export const ACCOUNTS_ACTIONS = {
  GET_ACCOUNTS_REQUEST: 'GET_ACCOUNTS_REQUEST',
  GET_ACCOUNTS_SUCCESS: 'GET_ACCOUNTS_SUCCESS',
  GET_ACCOUNTS_ERROR: 'GET_ACCOUNTS_ERROR',
  SELECT_ACCOUNT: 'SELECT_ACCOUNT',
};

export const accountsActionCreator = {
  GET_ACCOUNTS_REQUEST: () => ({ type: ACCOUNTS_ACTIONS.GET_ACCOUNTS_REQUEST }),
  GET_ACCOUNTS_SUCCESS: accounts => ({ type: ACCOUNTS_ACTIONS.GET_ACCOUNTS_SUCCESS, payload: {accounts} }),
  GET_ACCOUNTS_ERROR: payload => ({ type: ACCOUNTS_ACTIONS.GET_ACCOUNTS_ERROR, payload }),
  SELECT_ACCOUNT: account => ({ type: ACCOUNTS_ACTIONS.SELECT_ACCOUNT, payload: {account} }),
};
