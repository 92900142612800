import React, { useState } from 'react';
import { ModalButton } from '../../easy_lib/Advanced/ModalButton';
import { ExternalFormGenerator } from '../../easy_lib/Advanced/FormGenerator';
import { dateService } from '../../services/dateService';

const TEXT_CONSTS = {
  NO_PAYMENT: 'Pas de paiement',
  NO_ACCOUNT_OR_BILL: 'Pas de compte ni de facture sélectionnée'
};

export const BillPayerManagerComponent = ({ billSelected, account, addPayment }) => {
  const [values, setValues] = useState({
    [PAYMENT_KEYS.PAID]: billSelected.totalBilled,
    [PAYMENT_KEYS.REMAINING]: 0,
    [PAYMENT_KEYS.DATE]: dateService.getDateYYYYMMDD(),
  });
  const onChange = e => {
    const { name, value } = e.target;
    const valueToUpdate =
      name === PAYMENT_KEYS.PAID
        ? {
            [name]: value,
            [PAYMENT_KEYS.REMAINING]: parseInt(billSelected.totalBilled) - parseInt(value),
          }
        : { [name]: value };
    setValues({ ...values, ...valueToUpdate });
  };

  const showPaymentSection = () => {
    if (!billSelected.payment) {
      return <div>{TEXT_CONSTS.NO_PAYMENT} </div>;
    } else {
      return <div>{JSON.stringify(billSelected.payment)}</div>;
    }
  };

  return !(account && billSelected) ? (
    <div>{TEXT_CONSTS.NO_ACCOUNT_OR_BILL}</div>
  ) : (
    <div>
      <div>Compte: {account.name}</div>
      <br />
      <div>Bill number : {billSelected.billNumber}</div>
      <div>Command number : {billSelected.commandNumber}</div>
      <div>Total billed : {billSelected.totalBilled.toLocaleString()}</div>
      <br />
      <div>
        <div>Tickets facturés :</div>
        {billSelected.tickets.map(ticket => (
          <div>
            {ticket[0]} - {ticket[1]}
          </div>
        ))}
      </div>
      <ModalButton buttonValue={PAYMENT_CONST.ADD_A_PAYMENT}>
        <ExternalFormGenerator
          values={values}
          onChange={onChange}
          items={paymentItemsArray}
          onValidateAction={addPayment}
          onValidateText={PAYMENT_CONST.ADD_A_PAYMENT}
          title={PAYMENT_CONST.ADD_A_PAYMENT}
        />
      </ModalButton>
      {showPaymentSection()}
    </div>
  );
};

const PAYMENT_CONST = {
  ADD_A_PAYMENT: 'Add a payment',
  PAYMENT_TYPE: 'Mode de règlement',
  AMOUNT_PAID: 'Somme payée',
  AMOUNT_REMAINING: 'Somme restant à payer',
  PAYMENT_DETAILS: 'Détails',
  CREDIT_CARD: 'CARTE DE CREDIT',
  CHECK: 'Chèque',
  CASH: 'Cash',
  TRANSFER: 'Virement',
  MOBILE_MONEY: 'Mobile noney',
};

export const paymentTypes = [
  PAYMENT_CONST.CASH,
  PAYMENT_CONST.TRANSFER,
  PAYMENT_CONST.CHECK,
  PAYMENT_CONST.CREDIT_CARD,
  PAYMENT_CONST.MOBILE_MONEY,
].map((paymentType, index) => ({ value: index, display: paymentType }));

const PAYMENT_KEYS = {
  DATE: 'date',
  TYPE: 'type',
  DETAILS: 'details',
  PAID: 'paid',
  REMAINING: 'remaining',
};

const paymentItemsArray = [
  { type: 'date', label: 'Date de paiement', name: PAYMENT_KEYS.DATE, required: true },
  {
    type: 'select',
    name: PAYMENT_KEYS.TYPE,
    data: paymentTypes,
    label: PAYMENT_CONST.PAYMENT_TYPE,
    renderItem: item => item,
    required: true,
  },

  {
    type: 'textarea',
    label: PAYMENT_CONST.PAYMENT_DETAILS,
    name: PAYMENT_KEYS.DETAILS,
    style: { height: 100 },
  },
  { type: 'number', name: PAYMENT_KEYS.PAID, label: PAYMENT_CONST.AMOUNT_PAID, required: true },
  {
    type: 'number',
    name: PAYMENT_KEYS.REMAINING,
    label: PAYMENT_CONST.AMOUNT_REMAINING,
    required: true,
  },
];
