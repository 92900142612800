import React from 'react';
import { Column, Row } from '../../easy_lib/Layout';
import { theme } from '../../theme';
import { Button } from '../../easy_lib/Basics';
import { dateService } from '../../services/dateService';

const styles = {};

export const BillsComponent = ({ updateBill, ...props }) => {
  return (
    <Column>
      <Button onClick={props.getBills}>Refresh bills</Button>
      <div style={{ margin: '11px 16px 3px' }}>Recent bills :</div>
      <Column>
        {props.bills
          .filter(bill => !bill.paid)
          .map(bill => {
            const billSelected = props.billSelected === bill;
            return (
              <div
                style={{
                  padding: theme.getSpacing(2),
                  fontWeight: billSelected ? '800' : '',
                }}
                key={bill.billNumber}
                onClick={() => props.selectBill(bill)}
              >
                {bill.billNumber +
                  ' ' +
                  props.accountsById[bill.customerAccount] +
                  ' - ' +
                  dateService.getDateFromObjectID(bill._id)}
                {billSelected && (
                  <span>
                    <span
                      onClick={() => updateBill(bill)}
                      style={{ padding: theme.getSpacing(1), color: theme.colorUsages.info }}
                    >
                      Modifier
                    </span>
                    <span style={{ padding: theme.getSpacing(1), color: theme.colorUsages.danger }}>
                      Supprimer
                    </span>
                  </span>
                )}
              </div>
            );
          })}
      </Column>
      {props.children}
    </Column>
  );
};
