import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getAccountsFromServer } from '../../services/api/accounts';
import { ACCOUNTS_ACTIONS, accountsActionCreator } from './actions';
import { isUserLogged } from '../login/selector';

// Our worker Saga: will perform the async increment task
export function* retrieveAccounts() {
  try {
    const APIAccounts = yield call(getAccountsFromServer);
    yield put(
      accountsActionCreator.GET_ACCOUNTS_SUCCESS( APIAccounts)
    );
  } catch (e) {
    console.log(e);
  }
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export function* watchAccountsActionsSync() {
  yield takeLatest(ACCOUNTS_ACTIONS.GET_ACCOUNTS_REQUEST, retrieveAccounts);
}
