import React from 'react';
import { connect } from 'react-redux';
import { logInRequestActionCreator, logOutRequestActionCreator } from '../../modules/login/actions';
import { isUserLogged } from '../../modules/login/selector';
import { ConnexionComponent } from './Connexion.component';
import { LAST_USER_NAME } from '../../server/SERVER_CONST';

const mapStateToProps = state => {
  return {
    isLogged: isUserLogged(state),
    user: state.login.decodedToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    tryToLogIn: logInData => {
      console.log('try to log in', logInData);
      localStorage.setItem(LAST_USER_NAME, logInData.name);
      dispatch(logInRequestActionCreator(logInData));
    },
    logOut: () => {
      dispatch(logOutRequestActionCreator());
    }
  };
};

export const ConnexionContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const Connexion = ConnexionContainer(ConnexionComponent);