import { __TOKEN_KEY__, LAST_USER_NAME } from '../server/SERVER_CONST';

const getToken = () => localStorage && localStorage.getItem(__TOKEN_KEY__);

export const tokenService = {
  getToken,
  setToken: token => localStorage.setItem(__TOKEN_KEY__, token),
  decodeToken: token => {
    try {
      return JSON.parse(window.atob(token));
    } catch (e) {
      return '';
    }
  },
  getDecodedToken: () => {
    const token = getToken();
    try {
      return JSON.parse(window.atob(token));
    } catch (e) {
      return '';
    }
  },
  getLastUserName: () => (localStorage && localStorage.getItem(LAST_USER_NAME)) || '',
};
