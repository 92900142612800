import React from 'react';
import { Table } from '../../easy_lib/Basics/Table';
import { Column, Row } from '../../easy_lib/Layout';
import { generateLongDate } from '../../services/dateService';
import { addLeadingZero } from '../../services/numberService';
import { numbToLetters } from '../../services/numbToLetters';

export const billCreatorStyles = {
  secondLine: { fontSize: 14 },
  price: { textAlign: 'right' },
};

export const company = {
  name: 'Agence Française de Voyages',
  adress: 'B.P. 245, Yaounde',
  tel: [
    'Direction 24/7  +237 6 77 93 78 21',
    'Réservations  +237 6 77 93 78 22',
    'Comptabilité +237 6 77 93 78 23',
  ],
  mail: ['compta@afvtravel.com', 'reservation@afvtravel.com', 'direction@afvtravel.com'],
  contribuable_ID: 'N° CONTRIBUABLE : P036300272328L',
};

export const sortAlphabeticallyByField = field => (a, b) => (a[field] > b[field] ? 1 : -1);

export const TVA_RATE = 0.1925;

export const ticketsFunctions = {
  calculateTotals: tickets => {
    const totals = tickets.reduce(
      (final, ticket) => ({
        priceHT: final.priceHT + parseInt(ticket.priceHT),
        priceTTC: final.priceTTC + parseInt(ticket.priceTTC),
        fees: final.fees + parseInt(ticket.fees),
        total: final.total + parseInt(ticket.total),
      }),
      { priceHT: 0, fees: 0, total: 0, priceTTC: 0 },
    );
    totals.TVA = Math.ceil(totals.fees * TVA_RATE);
    totals.netToPay = totals.priceTTC + totals.fees + totals.TVA;
    totals.netToPayInLetters = numbToLetters(totals.netToPay).toUpperCase();
    totals.totalToPayInLetters = numbToLetters(totals.total).toUpperCase();
    return totals;
  },
};

export const BillCreator = ({
  tickets,
  customerAccount,
  className,
  groups = [],
  remarks = '',
  paymentType,
  billNumber = '000',
  withFS,
}) => {
  const [creationDate, setCreationDate] = React.useState(generateLongDate(new Date()));

  return (
    <Column className={className} style={{ margin: 10, color: 'black' }}>
      <BillHeader customerAccount={customerAccount} billNumber={billNumber} />
      <br />
      <BillMain tickets={tickets} date={creationDate} withFS={withFS} />
      <br />
      <BillBottom date={creationDate} remarks={remarks} paymentType={paymentType} withFS={withFS} />
    </Column>
  );
};

export const generateTicketNumberColumn = ticket => (
  <td style={{ width: 180, minWidth: 180, maxWidth: 180 }}>
    <div>{ticket.ticketNumber}</div>
    <div style={billCreatorStyles.secondLine}>{ticket.companyName}</div>
  </td>
);

const printRightTicketDates = flightDates => {
  let realFlightDates = flightDates;
  try {
    if (typeof flightDates === 'string') {
      realFlightDates = flightDates.split(',');
    }
  } catch (e) {}
  return (
    realFlightDates.length > 0 &&
    `DEP: ${realFlightDates[0]} ${realFlightDates.length > 1 ? `RET: ${realFlightDates[1]}` : ''}`
  );
};

const BillMain = ({ tickets, date, groups, withFS }) => {
  let ticketIndex = 0;

  const generateDesignationColumn = ticket => (
    <td>
      <div style={{ fontWeight: 600 }}>{ticket.name}</div>
      <div style={billCreatorStyles.secondLine}>{ticket.trip}</div>
      <div style={billCreatorStyles.secondLine}>{printRightTicketDates(ticket.flightDates)}</div>
    </td>
  );
  const generateCommandColumn = ({ purchaseOrder, projectNumber, description }) => (
    <td style={{ width: 200, minWidth: 200, maxWidth: 200 }}>
      <div>BC {purchaseOrder}</div>
      {description ? <div style={billCreatorStyles.secondLine}>{description}</div> : null}
      <div style={billCreatorStyles.secondLine}>{projectNumber}</div>
    </td>
  );
  const generateIndexColumn = () => {
    ticketIndex += 1;
    return <td style={{ width: 30, minWidth: 30, maxWidth: 30 }}>{addLeadingZero(ticketIndex)}</td>;
  };

  const generatePricesColumn = ticket =>
    withFS
      ? [
          <td style={billCreatorStyles.price} key={'priceTTC'}>
            {parseInt(ticket.priceTTC).toLocaleString('fr')}
          </td>,
          <td style={billCreatorStyles.price} key={'fees'}>
            {parseInt(ticket.fees).toLocaleString('fr')}
          </td>,
          <td style={{ ...billCreatorStyles.price, fontWeight: 600 }} key={'total'}>
            {ticket.total.toLocaleString('fr')}
          </td>,
        ]
      : [
          <td style={{ ...billCreatorStyles.price, fontWeight: 600 }} key={'total'}>
            {ticket.total.toLocaleString('fr')}
          </td>,
        ];

  const generateTicketLine = ticket => (
    <tr key={ticket._id}>
      {generateIndexColumn()}
      {generateCommandColumn(
        ticket.customerMetaData || { purchageOrder: '', projectNumber: '', description: '' },
      )}
      {generateTicketNumberColumn(ticket)}
      {generateDesignationColumn(ticket)}
      {generatePricesColumn(ticket)}
    </tr>
  );

  const generateTicketSection = ({ items, projectNumber, purchaseOrder, description, _id }) => {
    const totals = ticketsFunctions.calculateTotals(items);
    return (
      items.length > 0 && (
        <React.Fragment key={_id}>
          {items.map(item =>
            generateTicketLine(item, { purchaseOrder, projectNumber, description }),
          )}
        </React.Fragment>
      )
    );
  };

  const generateTotalsTable = totals => {
    return (
      <React.Fragment>
        {(withFS
          ? [
              // { name: 'HT', value: totals.priceHT },
              { name: 'Total hors FS', value: totals.priceTTC },
              { name: 'Frais de service', value: totals.fees },
              {
                name: 'Net à payer hors TVA (F CFA)',
                value: totals.total,
                style: { fontWeight: 800 },
              },
              { name: 'TVA 19,25 % sur FS', value: totals.TVA },
              {
                name: 'Net à payer (F CFA)',
                value: totals.netToPay,
                style: { fontWeight: 800 },
              },
            ]
          : [
              { name: 'Total', value: totals.total },
              { name: 'TVA', value: 0 },
              {
                name: 'Net à payer (F CFA)',
                value: totals.total,
                style: { fontWeight: 800 },
              },
            ]
        ).map(item => (
          <tr style={item.style} key={item.name}>
            <td colSpan={withFS ? '4' : '3'} style={{ border: 'none' }} />
            <td
              style={{ textAlign: 'right', border: withFS ? '' : 'none' }}
              colSpan={withFS ? '2' : '1'}
            >
              {item.name}
            </td>
            <td style={{ width: '12vw', textAlign: 'right' }}>{item.value.toLocaleString('fr')}</td>
          </tr>
        ))}
      </React.Fragment>
    );
  };

  const totals = ticketsFunctions.calculateTotals(
    groups ? groups.reduce((final, group) => [...final, ...group.items], []) : tickets,
  );

  const columnNames = withFS =>
    withFS
      ? ['#', 'Commande', 'Numéro de billet', 'Désignation', 'Montant', 'FS', 'Total']
      : ['#', 'Commande', 'Numéro de billet', 'Désignation', 'Total'];

  // console.log(groups, groups && [...groups].sort(sortAlphabeticallyByField('purchaseOrder')))
  return (
    <React.Fragment>
      <div>Yaoundé, le {date}</div>
      <Table style={{ verticalAlign: 'top', lineHeight: '20px', overflow: 'unset' }}>
        <thead>
          <tr>
            {columnNames(withFS).map(item => (
              <th key={item}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody style={{ verticalAlign: 'top' }}>
          {groups
            ? [...groups]
                .sort(sortAlphabeticallyByField('purchaseOrder'))
                .map(generateTicketSection)
            : [...tickets].map(generateTicketLine)}
          {generateTotalsTable(totals)}
        </tbody>
      </Table>
      <div style={{ fontWeight: 600, marginTop: 10 }}>{totals.totalToPayInLetters}</div>
      {withFS && <div># Sous réserve de fournir une attestation d'exonération de TVA #</div>}
    </React.Fragment>
  );
};

export const BillHeader = ({ customerAccount, billNumber, isCreditNote }) => (
  <div>
    <div style={{ display: 'flex', alignItems: 'stretch' }}>
      <img src="/AFV.png" id="companyLogo" alt="company logo" style={{ maxWidth: '60%' }} />
      {/*<span >{props.billType + ' n°'}</span> {props.billNumber}*/}
      <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {isCreditNote ? 'AVOIR' : 'FACTURE'} N°: AFV/21/000{billNumber}
        {isCreditNote ? 'AB' : 'FB'}
      </div>
    </div>
    <Row
      style={{
        justifyContent: 'space-between',
        margin: '10px 0',
        alignItems: 'stretch',
        marginTop: '2rem',
        flexWrap: 'nowrap',
      }}
    >
      {generateAFVDiv(company)}
      {generateCustomerDiv(customerAccount)}
    </Row>
  </div>
);

const generateAFVDiv = company => (
  <div style={{ flex: 1, lineHeight: '20px' }}>
    <div style={{ fontWeight: 600, fontSize: '1.2rem', marginBottom: 5 }}>{company.name}</div>
    <div>{company.adress}</div>
    {company.mail.map(mail => (
      <div key={mail}>{mail}</div>
    ))}
    {company.tel.map(tel => (
      <div key={tel}>{tel}</div>
    ))}
    <div>{company.contribuable_ID}</div>
  </div>
);

const generateCustomerDiv = customerAccount =>
  customerAccount ? (
    <div style={{ lineHeight: '20px', flex: 1 }}>
      <div style={{ fontSize: '1.2rem', marginBottom: 5 }}>{customerAccount['entire name']}</div>
      <div>{customerAccount.adress}</div>
      <div>
        {customerAccount.city + (customerAccount.country ? ' - ' + customerAccount.country : '')}
      </div>
      <div>{customerAccount.phone ? 'Tel :' + customerAccount.phone : ''}</div>
    </div>
  ) : (
    <div>No customer acccount selected</div>
  );

export function BillBottom({ limitDateHidden, date, remarks, isCreditNote, paymentType, text }) {
  return (
    <div style={{ paddingTop: 20 }}>
      {!isCreditNote && <div>Mode de paiement : {paymentType}</div>}
      {!isCreditNote && (
        <div className={limitDateHidden ? 'displayNone' : 'padding-5px '}>Echéance : {date}</div>
      )}
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        {remarks ? remarks.split(';').map(x => <div key={x}>{x}</div>) : ''}
      </div>

      <div>
        <span>{isCreditNote ? 'Le Bénéficiaire' : ''}</span>
        <span style={{ float: 'right', marginRight: 40 }}> {text || 'La Direction'}</span>
      </div>
    </div>
  );
}
