export const getDates = state => state.dates;
export const getDatesYYMMDD = state => {
  const dates = getDates(state);
  return {
    before: adaptDateFromBasicDates(dates.before),
    after: adaptDateFromBasicDates(dates.after),
  };
};
const adaptDateFromBasicDates = date =>
  date
    .split('-')
    .join('')
    .slice(2);
