import { ServerService } from '../../server/ServerService';
import { ObjectID } from 'bson';
import { API_ACTIONS } from '../../server/API_ACTIONS';

export const getTicketsFromServer = ({accounts, dates}) => {
  return ServerService.postToServer('', {
    _id: new ObjectID(),
    type: API_ACTIONS.TICKETS.GET,
    payload: { accounts, dates },
  })
    .then(r => {
      console.log('retour');
      return r.data;
    })
    .catch(e => {
      console.log('error during bill retrieving process');
      return [];
    });
};


export const getCreditNotesFromServer = ({accounts, dates}) => {
  return ServerService.postToServer('', {
    _id: new ObjectID(),
    type: API_ACTIONS.CREDIT_NOTES.GET,
    payload: { accounts, dates },
  })
    .then(r => {
      console.log('retour');
      return r.data;
    })
    .catch(e => {
      console.log('error during bill retrieving process');
      return [];
    });
};
