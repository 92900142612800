import { GET_TICKETS_SUCCESS, ITicketsActions, SELECT_TICKET } from './actions';
import { API_ACTIONS } from '../../server/API_ACTIONS';

export const initialState: { ticketsById: {}; tickets: any[]; ticketsSelected: any[] } = {
  ticketsById: {} as { [id: string]: { creditNoteMetadata: {} } },
  tickets: [],
  ticketsSelected: [],
};

export const ticketsReducer = (state = initialState, action: ITicketsActions) => {
  switch (action.type) {
    case API_ACTIONS.TICKETS.GET_SUCCESS:
    case GET_TICKETS_SUCCESS:
      console.log('SUCCESS', action);
      // @ts-ignore
      const ticketsReceived = action.payload;
      const newTicketsIDs = ticketsReceived.map(ticket => ticket._id);
      return {
        ...state,
        ticketsById: {
          ...state.ticketsById,
          ...ticketsReceived.reduce((newTicketsById, ticket) => {
            newTicketsById[ticket._id] = ticket;
            return newTicketsById;
          }, {}),
        },
      };
    case SELECT_TICKET:
      const ticketToToggle = action.payload;
      let ticketsSelected = [];
      if (ticketToToggle !== 'reset') {
        ticketsSelected = Array.isArray(ticketToToggle)
          ? ticketToToggle
          : toggleFromArray(state.ticketsSelected, action.payload._id, ticketToToggle._id);
      }
      return {
        ...state,
        ticketsSelected,
      };
    default:
      return state;
  }
};

export const toggleFromArray = (array, item, comparisonFunction) => {
  const finalArray = [...array];
  const itemToToggle = item;
  const itemIndexInArray = finalArray.indexOf(comparisonFunction || item);
  if (itemIndexInArray > -1) {
    finalArray.splice(itemIndexInArray, 1);
  } else {
    finalArray.push(itemToToggle);
  }
  return finalArray;
};

const toggleFromArrayWithFunction = (array, item, comparisonFunction) => {
  const finalArray = [...array];
  const itemToToggle = item;
  const itemIndexInArray = finalArray.findIndex(comparisonFunction);
  if (itemIndexInArray > -1) {
    finalArray.splice(itemIndexInArray, 1);
  } else {
    finalArray.push(itemToToggle);
  }
  return finalArray;
};
