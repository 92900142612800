import { ACCOUNTS_ACTIONS } from './actions';
import { keyBy, mapValues } from 'lodash';

export const initialState = {
  accounts: [],
  accountsById: {},
  accountsSelected: [],
};

export const accountsReducer = (
  state = initialState,
  action: { type: string; payload: any }
): any => {
  switch (action.type) {
    case ACCOUNTS_ACTIONS.GET_ACCOUNTS_SUCCESS:
      const accountsToAdd = action.payload.accounts;
      return {
        ...state,
        accounts: accountsToAdd,
        accountsById: {
          ...state.accountsById,
          ...mapValues(keyBy(accountsToAdd, '_id'), account => account.name),
        },
      };

    case ACCOUNTS_ACTIONS.SELECT_ACCOUNT:
      let accountsSelected = [...state.accountsSelected];
      const accountsSent = action.payload.account;
      if (accountsSent.length === 1) {
        const accountSelected = accountsSent[0];
        const accountClickedIndexInAccountsSelected = state.accountsSelected.findIndex(
          account => account._id === accountSelected._id
        );
        if (accountClickedIndexInAccountsSelected > -1) {
          accountsSelected.splice(accountClickedIndexInAccountsSelected, 1);
        } else {
          accountsSelected.push(accountSelected);
        }
      } else {
        accountsSelected = [...accountsSent];
      }
      return {
        ...state,
        accountsSelected,
      };
    default:
      return state;
  }
};
