import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ADD_BILL_PAYMENT_REQUEST, billsActionCreator, GET_BILLS_REQUEST } from './actions';
import { getBillsFromServer } from '../../services/api/bills';
import { isUserLogged } from '../login/selector';
import { getAccountsSelectedIds } from '../accounts/selector';
import { getDates } from '../dates/selector';

// Our worker Saga: will perform the async increment task
export function* retrieveBills(action) {
  console.log('call request');
  const accountsSelectedIds = yield select(getAccountsSelectedIds);
  const dates = yield select(getDates);
  const bills = yield call(getBillsFromServer, {accounts: accountsSelectedIds, dates});
  yield put(billsActionCreator.GET_BILLS_SUCCESS(bills));
}

// Our worker Saga: will perform the async increment task
export function* addBillPaymentSaga(action) {
  if (!(yield select(isUserLogged))) {
    return;
  }
  console.log('call request', action);
  // const accountsSelectedIds = yield select(getAccountsSelectedIds);
  // const dates = yield select(getDates);
  // const bills = yield call(getBillsFromServer, {accounts: accountsSelectedIds, dates});
  yield put(billsActionCreator.ADD_BILL_PAYMENT_SUCCESS(action.payload));
}



// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export function* watchBillsActionsSync() {
  yield takeLatest(GET_BILLS_REQUEST, retrieveBills);
  yield takeLatest(ADD_BILL_PAYMENT_REQUEST, addBillPaymentSaga);
}
