import { connect } from 'react-redux';
import { getAccountsSelected } from '../../modules/accounts/selector';
import { getCreditNotes } from '../../modules/creditNotes/selector';
import { getDates } from '../../modules/dates/selector';
import { getFlightDates } from './getFlightDates';
import { StatesComponent } from './states.components';

const mapStateToProps = state => {
  return {
    items: getCreditNotes(state) || [],
    dates: getDates(state) || { before: '', after: '' },
    accountSelected: getAccountsSelected(state)
      ? getAccountsSelected(state)[0]
      : { name: 'no name' },
    keysPossible,
    fileNameToSave: 'AVOIR'
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export const CreditNoteStates = connect(
  mapStateToProps,
  mapDispatchToProps
)(StatesComponent);

const keysPossible = {
  "Date d'emission": ticket => ticket.emissionDate || '',
  'Date de depart': ticket => getFlightDates(ticket)[0] || '',
  'Date de retour': ticket => getFlightDates(ticket)[1] || '',
  Nom: ticket => ticket.passengerName || '',
  'Compte client': ticket => ticket.customerAccount || '',
  Compagnie: ticket => ticket.companyName || '',
  'Numero billet': ticket => ticket.ticketNumber || '',
  Parcours: ticket => ticket.trip || '',
  Classe: ticket => '',
  'Numero de projet': ticket =>
    ticket.customerMetaData ? ticket.customerMetaData.projectNumber : '',
  'Bon de commande': ticket =>
    ticket.customerMetaData ? ticket.customerMetaData.purchaseOrder : '',
  'Description projet': ticket =>
    ticket.customerMetaData ? ticket.customerMetaData.description : '',
  Facture: ticket =>
    ticket['billed']
      ? ticket['billed']['bill_number']
        ? ticket['billed']['bill_number']
        : ' '
      : ' ',
  'BC/Trip': ticket => ticket.commandNumber || '',
  PNR: ticket => ticket.PNR || '',
  'Prix initial du billet': ticket => parseInt(ticket.initialTicketAmount || '', 10),
  Utilisé: ticket => parseInt(ticket.amountUsed || 0, 10),
  Penalités: ticket => parseInt(ticket.cancelationFees || 0, 10),
  FS: ticket => parseInt(ticket.agencyFees || 0, 10),
  Avoir: ticket => parseInt(ticket.netToRefund || 0, 10),
  Annulé: ticket => (ticket.canceled ? 'Annulé' : ''),
};
