import { LOG_IN_ERROR, LOG_IN_REQUEST, LOG_IN_SUCCESS, LOG_OUT_ACTION } from './actions';
import { __TOKEN_KEY__ } from '../../server/SERVER_CONST';
import { tokenService } from '../../services/tokenService';


const getLogStateFromToken = (token = tokenService.getToken()) => {
  return {
    isLogged: !!token,
    token: token,
    decodedToken: tokenService.decodeToken(token),
  };
};

export const logInReducer = (state = getLogStateFromToken(), action) => {
  switch (action.type) {
    case LOG_IN_REQUEST:
      console.log('requesting login reducer login');
      return state;
    case LOG_IN_SUCCESS:
      tokenService.setToken(action.payload.token);
      return {
        ...state,
        ...getLogStateFromToken(action.payload.token)
      };
    case LOG_IN_ERROR:
      console.log('log in attempt failed', action);
      return {
        ...state,
      };
    case LOG_OUT_ACTION:
      localStorage.removeItem(__TOKEN_KEY__);
      console.log('log out action');
      return {
        ...state,
        ...getLogStateFromToken(),
      };
    default:
      return state;
  }
};
