import * as React from 'react';
import { connect } from 'react-redux';

import { getDates } from '../../modules/dates/selector';
import { datesActionCreator } from '../../modules/dates/actions';
import { InputHandler } from '../../easy_lib/Advanced';

const TEXT_CONST = {
  AFTER: 'Après le',
  BEFORE: 'Avant le',
};

const DateFilterComponent = ({ dates, setDates, style }) => (
  <div style={{ marginBottom: 10, display: 'flex', ...style }} className={'card'}>
    {[
      { name: 'after', type: 'date', label: TEXT_CONST.AFTER },
      { name: 'before', type: 'date', label: TEXT_CONST.BEFORE },
    ].map(item => (
      <InputHandler
        margin="dense"
        {...item}
        key={item.name}
        row
        value={dates[item.name]}
        onChange={e => setDates({ ...dates, [item.name]: e.target.value })}
      />
    ))}
  </div>
);

const mapStateToProps = state => ({
  dates: getDates(state),
});

const mapDispatchToProps = dispatch => ({
  setDates: dates => {
    dispatch(datesActionCreator.SET_DATES(dates));
  },
});

export const DateFilter = connect(
  mapStateToProps,
  mapDispatchToProps
)(DateFilterComponent);
