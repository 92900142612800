import React from 'react';
import { Table } from '../../easy_lib/Basics/Table';

const styles = {
  isBilled: ticket => (ticket.billed ? { backgroundColor: '#c83f52' } : {}),
  hasCustomerData: ticket => (ticket.customerMetaData ? { backgroundColor: '#eded00' } : {}),
};

export const DataTable = ({
  items,
  keys,
  title,
  Header,
  onItemClick = () => null,
  onItemContextMenu = () => null,
  itemStyle = () => {},
  isBilledCheck = ticket => ticket.billed,
  ...props
}) => (
  <div {...props}>
    {Header && <Header />}
    <div style={{ margin: '11px 16px 3px' }}>{title}</div>

    <Table>
      <thead>
        <tr style={{ textAlign: 'left', textTransform: 'uppercase' }}>
          {keys.map((key, index) => (
            <th key={index} style={{ fontWeight: 800 }}>
              {typeof key === 'string' ? key : key.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map(ticket => (
          <tr
            key={ticket._id}
            style={{
              ...itemStyle(ticket),
              ...(isBilledCheck(ticket) ? { background: '#c83f52' } : {}),
            }}
            onClick={() => onItemClick(ticket)}
            onContextMenu={e => {
              e.preventDefault();
              onItemContextMenu(ticket, e);
            }}
          >
            {keys.map(key => (
              <td key={JSON.stringify(key)}>
                {' '}
                {typeof key === 'string' ? ticket[key] : key.value(ticket)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
);
