import React from 'react';
import { SearchBar } from '../../components/SearchBar/SearchBar';
import { FormGenerator } from '../../easy_lib/Advanced';
import { ServerService } from '../../server/ServerService';
import { ModalButton } from '../../easy_lib/Advanced/ModalButton';
import { Button } from '../../easy_lib/Basics';

export const CreditNoteCreateComponent = ({ tickets }) => {
  const [{ ticket, creditNote }, setTicketToUpdate] = React.useState({
    ticket: {},
    creditNote: {},
  });

  const isCreditNoteExisting = creditNote.initialTicketAmount;
  return (
    <div>
      <form
        onSubmit={e => {
          e.preventDefault();
          ServerService.CREDIT_NOTE.GET_TICKET_AND_CREDIT_NOTE(e.target.elements[0].value).then(
            result => {
              const { ticket, creditNote } = result.data;
              setTicketToUpdate({ ticket: ticket || {}, creditNote: creditNote || {} });
            }
          );
        }}
      >
        <SearchBar
          placeholder="numero de ticket"
          label="Entrer le numero du billet svp"
          defaultValue="040-4593849620"
          className="input"
        />
      </form>
      {ticket.name ? (
        <div className="columns" style={{ padding: 20 }}>
          <div className="column">{showTicketData(ticket)}</div>
          <div className="column">
            <FormGenerator
              title={isCreditNoteExisting ? "Mettre à jour l'avoir" : 'Creer avoir'}
              items={[
                {
                  label: 'Montant initial',
                  type: 'number',
                  name: 'initialTicketAmount',
                  row: true,
                },
                { label: 'Montant utilisé', type: 'number', name: 'amountUsed', row: true },
                { label: "Frais d'annulation", type: 'number', name: 'cancelationFees', row: true },
                { label: 'Frais de service', type: 'number', name: 'agencyFees', row: true },
                { label: 'Net à rembourser', type: 'number', name: 'netToRefund', row: true },
              ]}
              initialData={
                isCreditNoteExisting
                  ? creditNote
                  : {
                      initialTicketAmount: ticket.total,
                      amountUsed: 0,
                      cancelationFees: 0,
                      agencyFees: 0,
                      netToRefund: 0,
                    }
              }
              onChange={(dataToSave, dataChanged) => {
                dataToSave.netToRefund =
                  parseInt(dataToSave.initialTicketAmount) -
                  parseInt(dataToSave.amountUsed) -
                  parseInt(dataToSave.cancelationFees) -
                  parseInt(dataToSave.agencyFees);
                return dataToSave || 0;
              }}
              onValidateAction={data =>
                ServerService.CREDIT_NOTE.UPSERT(ticket._id, data).then(result => {
                  alert('Avoir mis à jour');
                  setTicketToUpdate({ ticket, creditNote: data });
                })
              }
              onValidateText="Mettre à jour"
            />
            <ModalButton
              modalStyle={{ padding: 0, overflow: 'hidden' }}
              buttonValue="Supprimer l'avoir"
              danger
              style={{ width: '100%' }}
            >
              {closeModal => (
                <>
                  <div class="modal-card">
                    <header class="modal-card-head">
                      <p class="modal-card-title">Confirmer suppression ?</p>
                      <button class="delete" aria-label="close" onClick={closeModal} />
                    </header>
                    <footer class="modal-card-foot">
                      <button class="button" onClick={closeModal}>
                        Cancel
                      </button>
                      <button
                        class="button is-danger"
                        onClick={() =>
                          ServerService.CREDIT_NOTE.DELETE(ticket._id).then(result => {
                            alert('Avoir supprimé', JSON.stringify(result.data, null, 4));
                            setTicketToUpdate({ ticket, creditNote: {} });
                            closeModal();
                          })
                        }
                      >
                        Supprimer
                      </button>
                    </footer>
                  </div>
                </>
              )}
            </ModalButton>
          </div>
        </div>
      ) : (
        <div style={{ padding: 20 }}>
          Pas de ticket trouvé correspondant au numero de billet entré
        </div>
      )}
    </div>
  );
};

const dataToShow = {
  ticketNumber: 'Numero de ticket',
  date: "Date d'emission",
  companyName: 'Compagnie',
  trip: 'Voyage',
  customerAccount: 'Compte client',
  name: 'Nom passager',
  priceHT: 'Prix HT',
  priceTTC: 'Prix TTC',
  fees: 'Frais de service',
  total: 'Prix total',
};

const showTicketData = ticket => {
  return Object.entries(dataToShow).map(([key, value]) => {
    return (
      <div>
        <span>{value} : </span> {ticket[key]}
      </div>
    );
  });
};
