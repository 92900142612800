import React, { useState } from 'react';
import { Row } from '../../easy_lib/Layout';
import { FormGenerator, Modal } from '../../easy_lib/Advanced';
import { ObjectID } from 'bson';
import { Button } from '../../easy_lib/Basics';

const TEXT_CONSTS = {};

const ListItemCreator = ({ onChange, initialData2 }) => {
  const [initialData, setInitialData] = React.useState({});
  const [modalOpen, setModalOpen] = React.useState(0);
  const [items, setItems] = React.useState([]);
  const updateItems = newItems => {
    setItems(newItems);
    onChange(newItems);
  };
  React.useEffect(() => setItems(initialData2), [initialData2]);
  return (
    <Row>
      <ButtonWithModal
        open={modalOpen}
        value={TEXT_CONSTS.NEW_GROUP}
        onClick={() => setInitialData(null)}
      >
        {closeModal => (
          <FormGenerator
            items={ticketGroupField}
            title={TEXT_CONSTS.NEW_GROUP}
            initialData={initialData}
            onValidateText={TEXT_CONSTS.NEW_GROUP}
            onValidateAction={data => {
              updateItems(
                initialData
                  ? replaceItemInArray([...items], data, item => item === initialData)
                  : [...items, { ...data, _id: new ObjectID().toString() }]
              );
              setModalOpen(-(Math.abs(modalOpen) + 1));
            }}
          />
        )}
      </ButtonWithModal>
      {items.map(item => (
        <div
          key={item.projectNumber + item.purchaseOrder}
          style={{ marginLeft: 10, border: '1px solid grey', borderRadius: 5, padding: 10 }}
          onClick={e => {
            setModalOpen(Math.abs(modalOpen) + 1);
            setInitialData(item);
          }}
        >
          {item.projectNumber} | {item.purchaseOrder}
          <span
            style={{ marginLeft: 10 }}
            onClick={e => {
              e.stopPropagation();
              updateItems(items.filter(itemToDelete => item !== itemToDelete));
            }}
          >
            x
          </span>
        </div>
      ))}
    </Row>
  );
};

const replaceItemInArray = (initialItemArray, newItem, comparisonFunction) => {
  return initialItemArray.map(item => (comparisonFunction(item) ? newItem : item));
};

const ticketGroupField = [
  {
    name: 'projectNumber',
    type: 'text',
    label: 'Numero de projet',
    placeholder: 'Numero de projet',
    required: true,
  },
  {
    name: 'purchaseOrder',
    type: 'text',
    label: 'Bon de commande',
    placeholder: 'Bon de commande',
    required: true,
  },
  {
    name: 'description',
    type: 'text',
    label: 'Description',
    placeholder: 'Description de la section',
  },
];

export const ButtonWithModal = ({ value, open, children, onClick, ...props }) => {
  const [modalOpen, setModalOpen] = useState(false);
  React.useEffect(() => {
    // Met à jour le titre du document via l’API du navigateur
    setModalOpen(open > 0);
  }, [open]);

  return (
    <div>
      <button
        onClick={() => {
          setModalOpen(true);
          onClick && onClick();
        }}
        {...props}
      >
        {value}
      </button>
      {modalOpen && (
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        >
          {children(() => setModalOpen(false))}
        </Modal>
      )}
    </div>
  );
};
