import React, { useState } from 'react';
import { Column, Row } from '../../easy_lib/Layout';
import { theme } from '../../theme';
import { BillCreator, ticketsFunctions } from './billCreator';
import { DataTable } from '../../components/DataTable/DataTable';
import { InputHandler, Modal } from '../../easy_lib/Advanced';
import { Button } from '../../easy_lib/Basics';
import { TicketBillingDataUpdater } from '../../components/TicketBillingData/TicketBillingDataUpdater';
import { toggleFromArray } from '../../modules/tickets/reducer';
import { ButtonWithModal } from './tokeep';
import { Tickets } from '../tickets';
import { sortByBCAndNameAndTicketNumber } from '../../services/sortFunction';

const styles = {};

const keysToShow = [
  'date',
  'customerAccount',
  'name',
  'ticketNumber',
  'companyName',
  'trip',
  'total',
  'fees',
  {
    title: 'Numero de projet',
    value: item => item.customerMetaData && item.customerMetaData.projectNumber,
  },
  { title: 'BC', value: item => item.customerMetaData && item.customerMetaData.purchaseOrder },
  { title: 'Facture', value: item => item.billed && item.billed.bill_number },
];

const TEXT_CONSTS = {
  NEW_GROUP: 'NEW GROUP',
  BILL_GROUPED_QUESTION: 'Facture groupée ?',
  SHOW_ALREADY_BILLED_TICKETS: 'Montrer billets déjà facturés ? ',
};

export const CreateBillComponent = ({
  ticketsSelected,
  accountsSelected,
  children,
  createBill,
  addCustomerMetaData,
  ...props
}) => {
  const initialData = {
    tickets: ticketsSelected.filter(ticket => !ticket.billed),
    remarks: '',
  };

  console.log(initialData);
  const tickets = initialData.tickets;
  const [ticketsSelectedIds, setTicketSelectedIds] = React.useState(
    ticketsSelected.map(ticket => ticket._id)
  );
  const [showBilledTickets, setShowBilledTickets] = React.useState(false);
  const [remarks, setRemarks] = React.useState(initialData.remarks);
  const [ticketToUpdate, setTicketToUpdate] = React.useState({});
  const [modalUpdateVisible, setModalUpdateVisible] = React.useState(false);
  const [withFSCheckbox, setWithFSCheckboxValue] = React.useState(true);

  const ticketsToBill = ticketsSelected
    .filter(t => ticketsSelectedIds.includes(t._id))
    .sort(sortByBCAndNameAndTicketNumber);

  const selectTicket = ticket => {
    setTicketSelectedIds(toggleFromArray(ticketsSelectedIds, ticket._id));
  };

  const onItemRightClickAction = ticket => {
    setTicketToUpdate(ticket);
    setModalUpdateVisible(true);
  };
  return (
    <div>
      <h1>Créer nouvelle facture</h1>

      <Modal open={modalUpdateVisible} onClose={() => setModalUpdateVisible(false)}>
        <TicketBillingDataUpdater
          ticket={ticketToUpdate}
          onValidateAction={data => {
            console.log(data);
            addCustomerMetaData({ ticketId: ticketToUpdate._id, customerMetaData: data });
          }}
        />
      </Modal>
      <Row>
        <Button
          onClick={() => {
            const totals = ticketsFunctions.calculateTotals(ticketsToBill);
            console.log('total facutre', totals);
            createBill(
              {
                tickets: ticketsToBill.map(t => [t._id, t.total]),
                accounts: accountsSelected,
                customerAccount: accountsSelected[0]._id,
                customerName: accountsSelected[0].name,
                remarks,
                totalBilled: totals.total,
              },
              ticketsToBill
            );
          }}
          style={{ marginRight: 10 }}
          info
        >
          ENREGISTRER FACTURE
        </Button>
        <ButtonWithModal value="AJOUTER BILLETS" success>
          {closeModal => <Tickets />}
        </ButtonWithModal>
        <InputHandler
          row
          type="checkbox"
          onClick={() => setShowBilledTickets(!showBilledTickets)}
          label={`${TEXT_CONSTS.SHOW_ALREADY_BILLED_TICKETS} (${
            tickets.filter(ticket => ticket.billed).length
          })`}
          value={showBilledTickets}
        />
      </Row>
      <InputHandler
        row
        type="textarea"
        onChange={e => setRemarks(e.target.value)}
        label={'Remarks'}
        value={remarks}
        style={{ flex: 1, minHeight: 100, marginBottom: 10 }}
      />
      <DataTable
        items={ticketsSelected}
        onItemContextMenu={onItemRightClickAction}
        keys={keysToShow}
        onItemClick={selectTicket}
        title={'Tickets Found'}
        itemStyle={item => (ticketsSelectedIds.includes(item._id) ? { fontWeight: 700 } : {})}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
          fontSize: '24px',
        }}
      >
        <input
          type="checkbox"
          checked={withFSCheckbox}
          onChange={e => setWithFSCheckboxValue(e.target.checked)}
        />
        <label htmlFor="">Afficher les FS ? </label>
      </div>
      <BillCreator
        className="bill_preview"
        tickets={ticketsToBill}
        remarks={remarks}
        customerAccount={accountsSelected[0]}
        billNumber={initialData.billNumber}
        withFS={withFSCheckbox}
      />
      {children}
    </div>
  );
};
