import { call, put, takeEvery,takeLatest, take } from "redux-saga/effects";
import {
  LOG_IN_REQUEST,
  logInErrorActionCreator,
  logInSuccessActionCreator
} from "./actions";
import { logInRequest } from "../../services/api/logIn";

// Our worker Saga: will perform the async increment task
export function* logInSaga(action) {
  console.log('here1');
  try {
    const token = yield call(logInRequest, action.payload.userData);
    console.log('connexion try 3', token);

    yield put(logInSuccessActionCreator(token));
  } catch (error) {
    console.log('connexion error', error);

    yield put(logInErrorActionCreator(error));
  }
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export function* watchLogInActionsSync() {
  yield takeEvery(LOG_IN_REQUEST, logInSaga);
}
