import React from 'react';

export const List = ({ data, renderItem, ListHeader, style }) => {
  if (!Array.isArray(data)) {
    console.error('Bad data type in List for ', data);
    return null;
  }
  return <div style={style}>
    {ListHeader}
    {data.map((item, index) => renderItem(item, index))}</div>;
};
