import React from 'react';
import { Column, Row } from '../../easy_lib/Layout';
import { DataTableFromArray } from '../../components/DataTable/DataTableFromArray';
import { SelectionSumUp, DataSelectorWithOrder } from '../../components/Layout/selectionSumUp';
import { withModal } from '../../components/HOC/withModal';

const styles = {};

export const StatesComponent = ({
  items,
  children,
  dates,
  accountSelected = {},
  keysPossible,
  fileNameToSave = 'BILLETS',
}) => {
  const allKeysPossible = Object.keys(keysPossible);

  const [selectedKeys, setSelectedKeys] = React.useState(allKeysPossible);
  const [sortKeys, setSortKeys] = React.useState(["Date d'emission", 'Nom']);
  const dataArray = createDataArray(
    items.sort(sortingFunction(sortKeys, keysPossible)),
    selectedKeys,
    keysPossible
  );

  // React.useEffect(() => {
  //   setDownloadLinkButton(exportCSVFileButtonLink(dataArray, 'Etats de test'));
  // }, [tickets]);

  const SelectionSumUpWithModal = withModal(SelectionSumUp);
  return (
    <Column>
      <Row>
        <SelectionSumUpWithModal
          items={sortKeys}
          title={'Ranger par (cliquer pour modifier)'}
          style={{ flex: 1 }}
        >
          <DataSelectorWithOrder
            items={allKeysPossible}
            initialSelection={sortKeys}
            title={'Filtrer selon les champs'}
            onValidate={newSortKeys => setSortKeys(newSortKeys)}
          />
        </SelectionSumUpWithModal>
        <SelectionSumUpWithModal
          items={selectedKeys}
          title={'Afficher les colonnes (cliquer pour modifier)'}
          style={{ flex: 1 }}
        >
          <DataSelectorWithOrder
            items={allKeysPossible}
            initialSelection={selectedKeys}
            title={'Selectionner les colonnes à afficher'}
            onValidate={newSelectedKeys => setSelectedKeys(newSelectedKeys)}
          />
        </SelectionSumUpWithModal>
      </Row>

      {exportCSVFileButtonLink(
        dataArray,
        `Etat ${fileNameToSave} ${accountSelected.name} du ${dates.after} au ${dates.before}`
      )}
      <DataTableFromArray dataArray={dataArray} />
      <Column />
      {children}
    </Column>
  );
};

const alphabetLetters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k'];

const sortingFunction = (keysPossibleArray, keysPossible) => {
  const getSortingKey = item =>
    keysPossibleArray.reduce((final, keyPossible) => {
      let itemToAdd = keysPossible[keyPossible](item);
      if (typeof itemToAdd === 'number') {
        const itemToAddLength = ('' + itemToAdd).length;
        itemToAdd = alphabetLetters[itemToAddLength] + itemToAdd;
      }
      return final + itemToAdd;
    }, '');

  return (ticketA, ticketB) => {
    const ticketAsortingValue = getSortingKey(ticketA);
    const ticketBsortingValue = getSortingKey(ticketB);
    if (ticketAsortingValue < ticketBsortingValue) {
      return -1;
    }
    if (ticketAsortingValue > ticketBsortingValue) {
      return 1;
    }
    return 0;
  };
};

const convertToCSV = objArray => {
  let array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (let index in array[i]) {
      if (line !== '') line += ';';
      line += array[i][index];
    }
    str += line + '\r\n';
  }
  return str;
};

const exportCSVFileButtonLink = (items, fileTitle) => {
  let jsonObject = JSON.stringify(items);

  let csv = convertToCSV(jsonObject);
  let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  let exportedFileName = fileTitle + '.csv' || 'export.csv';
  // function pick(obj, keys) {
  //   return keys
  //     .map(k => (k in obj ? { [k]: obj[k] } : {}))
  //     .reduce((res, o) => Object.assign(res, o), {});
  // }
  let url = URL.createObjectURL(blob);
  return (
    <a href={url} download={exportedFileName}>
      Telecharger {exportedFileName}
    </a>
  );
};

const createDataArray = (tickets, keysToUse = [], keysPossible) => {
  const ticketKeys = keysToUse;
  let dataRows = [ticketKeys];
  for (const ticket of tickets) {
    const ticketData = ticketKeys.map(
      ticketKey => (keysPossible[ticketKey] && keysPossible[ticketKey](ticket)) || ''
    );
    dataRows.push(ticketData);
  }
  return dataRows;
};
