import { IRootReducer } from '../rootReducer';

export const getAccounts = (state: IRootReducer) =>
  state.accounts.accounts.sort((a, b) => a.name.localeCompare(b.name));
export const getAccountsById = (state: IRootReducer) => state.accounts.accountsById;

export const getAccountsSelected = (state: IRootReducer) => state.accounts.accountsSelected;
export const getAccountsSelectedIds = (state: IRootReducer) =>
  getAccountsSelected(state).map(account => account._id);

export const getAccountById = (state: IRootReducer, id: string) =>
  getAccounts(state).find(account => account._id === id);
