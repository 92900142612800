import React, { useEffect } from 'react';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { getTicketsNotCanceled } from '../../modules/tickets/selector';
import { getDates } from '../../modules/dates/selector';
import { getAccountsSelected } from '../../modules/accounts/selector';
import { Row } from '../../easy_lib/Layout';
import { groupBy } from 'lodash';
import { TICKETS_ACTIONS } from '../../modules/creditNotes/actions';

const mapStateToProps = state => {
  return {
    tickets: getTicketsNotCanceled(state) || [],
    dates: getDates(state) || { before: '', after: '' },
    accountSelected: getAccountsSelected(state)
      ? getAccountsSelected(state)[0]
      : { name: 'no name' },
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'My First dataset',
      fill: false,
      lineTension: 0.1,
      data: [65, 59, 80, 81, 56, 55, 40],
    },
  ],
};

const data2 = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'My First dataset',
      fill: false,
      lineTension: 0.1,
      data: [65, 59, 80, 81, 56, 55, 40],
    },
  ],
};

const withChartSizeControl = Component => {
  return props => (
    <div
      className="chart"
      style={{
        position: 'relative',
        height: props.height,
        width: props.width,
      }}
    >
      <Component {...props} />
    </div>
  );
};

const LineDiv = withChartSizeControl(Line);

const BarDataDiv = withChartSizeControl(Bar);

const retrieveDataByGroup = (tickets, getTicketGroup, fieldsFilterFunction, fields) => {
  //Fields = [{color}]
  const dataToFilter = fieldsFilterFunction ? tickets.map(fieldsFilterFunction) : tickets;
  const ticketsGrouped = groupBy(dataToFilter, x => getTicketGroup(x));
  const allGroups = Object.keys(ticketsGrouped);
  const color= "#c30f08"
  return {
    data: {
      labels: Object.keys(ticketsGrouped),
      datasets: [
        {
          label: 'Nombre',
          borderColor: color,
          fill: true,
          backgroundColor: color,
          yAxisID: 'A',
          data: allGroups.map(group => ticketsGrouped[group].length),
        },
        {
          label: 'Valeur',
          fill: false,
          lineTension: 0.1,
          yAxisID: 'B',
          data: allGroups.map(group =>
            ticketsGrouped[group].reduce((total, ticket) => total + parseInt(ticket.total), 0)
          ),
        },
      ],
    },
    options: {
      scales: {
        yAxes: [
          {
            id: 'A',
            type: 'linear',
            position: 'left',
            color
          },
          {
            id: 'B',
            type: 'linear',
            position: 'right',
          },
        ],
      },
      maintainAspectRatio: false,
    },
  };
};

const StatsAndGraphsComponent = ({ tickets, dates }) => {
  const [ticketsGroupedByDate, setTicketsGroupedByEmissionDate] = React.useState({});
  const [ticketsGroupedByCompany, setTicketsGroupedByCompany] = React.useState({options: {maintainAspectRatio: false}});
  const [ticketsByPN, setTicketsByPN] = React.useState({options: {maintainAspectRatio: false}});
  useEffect(() => {
    setTicketsGroupedByEmissionDate(groupBy(tickets, x => (x.date ? x.date : 'NO DATE')));
    setTicketsGroupedByCompany(retrieveDataByGroup(tickets, x => (x.companyName ? x.companyName : 'OTHERS')));
    setTicketsByPN(retrieveDataByGroup(tickets, x => (x.customerMetaData ? x.customerMetaData.projectNumber : 'NO PN')));
  }, [tickets]);

  console.log(tickets);

  const allDates = Object.keys(ticketsGroupedByDate) || [];
  const data = allDates.map(date => ticketsGroupedByDate[date].length);
  const ticketNumberSoldByDate = {
    labels: Object.keys(ticketsGroupedByDate),
    datasets: [
      {
        label: 'Billets vendus par jour d emission',
        fill: false,
        lineTension: 0.1,
        data,
      },
    ],
  };

  // const allCompanies = Object.keys(ticketsGroupedByCompany);
  // const ticketNumberSoldByCompanyDataAndOptions = {
  //   data: {
  //     labels: Object.keys(ticketsGroupedByCompany),
  //     datasets: [
  //       {
  //         label: 'Nombre',
  //         borderColor: 'rgba(75,192,192,1)',
  //         fill: true,
  //         backgroundColor: 'rgba(75,192,192,1)',
  //         yAxisID: 'A',
  //         data: allCompanies.map(company => ticketsGroupedByCompany[company].length),
  //       },
  //       {
  //         label: 'Valeur',
  //         fill: false,
  //         lineTension: 0.1,
  //         yAxisID: 'B',
  //         data: allCompanies.map(company =>
  //           ticketsGroupedByCompany[company].reduce(
  //             (total, ticket) => total + parseInt(ticket.total),
  //             0
  //           )
  //         ),
  //       },
  //     ],
  //   },
  //   options: {
  //     scales: {
  //       yAxes: [
  //         {
  //           id: 'A',
  //           type: 'linear',
  //           position: 'left',
  //         },
  //         {
  //           id: 'B',
  //           type: 'linear',
  //           position: 'right',
  //         },
  //       ],
  //     },
  //     maintainAspectRatio: false,
  //   },
  // };

  return (
    <Row>
      {/* <ChartExample width={200} style={{backgroundColor: 'red'}}/> */}
      <BarDataDiv
        width={'50%'}
        height={250}
        data={ticketNumberSoldByDate}
        options={{ maintainAspectRatio: false }}
      />
      <LineDiv data={data2} width={'50%'} options={{ maintainAspectRatio: false }} height={190} />
      <BarDataDiv width={'100%'} height={400} {...ticketsGroupedByCompany} />
      <BarDataDiv width={'100%'} height={400} {...ticketsByPN} />

      {/* <Doughnut data={[{ x: 'ninja', value: 100 }, { x: 'ninja2', y: 1002 }]} /> */}
    </Row>
  );
};

export const StatsAndGraphs = connect(
  mapStateToProps,
  mapDispatchToProps
)(StatsAndGraphsComponent);


// {
//   "_id": "071-3656206114",
//   "airFileName": "AIR41137.AIR",
//   "ticketNumber": "071-3656206114",
//   "name": "DE CAMPOS/BETTINA MRS",
//   "accountName": "GIZ\r",
//   "customerAccount": "GIZ",
//   "date": "190701",
//   "priceHT": "346600",
//   "priceTTC": "543220",
//   "companyName": "ETHIOPIAN AIRLINES",
//   "commissionRate": "1",
//   "commissionValue": 3466,
//   "fees": "58500",
//   "total": 601720,
//   "flightDates": [
//     "11 sept 19",
//     "22 sept 19"
//   ],
//   "trip": "NSI-ADD-MBA-ADD-NSI",
//   "airTicketNumber": "00374227",
//   "PNR": "V2RX6V",
//   "airTicketNumberPrefix": "01",
//   "emissionType": "TTP/RT",
//   "emissionDate": "190619;190701;190701",
//   "tripsInDetail": [
//     {
//       "ticketNumber": "071-3656206114",
//       "tripType": "O",
//       "departureAirportCode": "NSI",
//       "departureCity": "YAOUNDE",
//       "arrivalAirportCode": "ADD",
//       "arrivalCity": "ADDIS ABABA",
//       "company": "ET",
//       "flightNumber": "0925L",
//       "departureDayAndTime": "11SEP1425",
//       "arrivalDayAndTime": "11SEP2030",
//       "luggageNumber": "2PC",
//       "checkinTimeMax": "",
//       "tripLength": "0405 "
//     },
//     {
//       "ticketNumber": "071-3656206114",
//       "tripType": "X",
//       "departureAirportCode": "ADD",
//       "departureCity": "ADDIS ABABA",
//       "arrivalAirportCode": "MBA",
//       "arrivalCity": "MOMBASA",
//       "company": "ET",
//       "flightNumber": "0322L",
//       "departureDayAndTime": "12SEP0910",
//       "arrivalDayAndTime": "12SEP1130",
//       "luggageNumber": "2PC",
//       "checkinTimeMax": "",
//       "tripLength": "0220 "
//     },
//     {
//       "ticketNumber": "071-3656206114",
//       "tripType": "O",
//       "departureAirportCode": "MBA",
//       "departureCity": "MOMBASA",
//       "arrivalAirportCode": "ADD",
//       "arrivalCity": "ADDIS ABABA",
//       "company": "ET",
//       "flightNumber": "0323L",
//       "departureDayAndTime": "22SEP1855",
//       "arrivalDayAndTime": "22SEP2115",
//       "luggageNumber": "2PC",
//       "checkinTimeMax": "",
//       "tripLength": "0220 "
//     },
//     {
//       "ticketNumber": "071-3656206114",
//       "tripType": "X",
//       "departureAirportCode": "ADD",
//       "departureCity": "ADDIS ABABA",
//       "arrivalAirportCode": "NSI",
//       "arrivalCity": "YAOUNDE",
//       "company": "ET",
//       "flightNumber": "0923L",
//       "departureDayAndTime": "23SEP0900",
//       "arrivalDayAndTime": "23SEP1105",
//       "luggageNumber": "2PC",
//       "checkinTimeMax": "",
//       "tripLength": "0405 "
//     }
//   ],
//   "creationDate": 1562060560138.9072,
//   "valid": true,
//   "customerId": "5d1b26c141b99b0008457a6e",
//   "checked": true,
//   "customerMetaData": {
//     "projectNumber": "PN 99.9239.7-001.00",
//     "purchaseOrder": "83327902",
//     "description": ""
//   },
//   "billed": {
//     "bill_id": "5d2bb274819b1b00170fd23d",
//     "bill_number": 342,
//     "date": 1564075541279,
//     "value_billed": 1
//   }
// }