import React from 'react';
import { withModal } from '../HOC/withModal';
import { connect } from 'react-redux';
import { getAccountsSelected } from '../../modules/accounts/selector';

const TEXT_CONST = {
  ACCOUNT_SELECTED: 'Comptes selectionnés',
};

const styles = {
  accountSelectedDiv: {
    maxHeight: 50,
    overflow: 'scroll',
    display: 'flex',
    flexWrap: 'wrap',
  },
  titleDiv: {
    marginBottom: 10,
    fontWeight: 600
  },
  cardStyle: {
    backgroundColor: '#f4f4f4',
    borderRadius: 3,
  },
};

const AccountsSelectedBase = ({ accountsSelected, style, ...props }) => (
  <div style={{...style }} className={'card'} {...props}>
    <div style={styles.titleDiv}>{TEXT_CONST.ACCOUNT_SELECTED} ({accountsSelected.length})</div>
    <div style={styles.accountSelectedDiv}>
      {accountsSelected.map(account => (
        <span style={{ marginLeft: 5 }}>{account.name + ' / '}</span>
      ))}
    </div>
  </div>
);

const mapStateToProps = state => {
  return {
    accountsSelected: getAccountsSelected(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {}

export const AccountsSelected = withModal(connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountsSelectedBase));
