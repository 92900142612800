import { connect } from 'react-redux';
import {
  getAccountsById,
  getAccountsSelected,
  getAccountsSelectedIds,
} from '../../modules/accounts/selector';
import { CreditNotesComponent } from './creditNotesComponent';
import { getDates } from '../../modules/dates/selector';
import { ServerService } from '../../server/ServerService';
import { getCreditNotes, getCreditNotesSelectedIds } from '../../modules/creditNotes/selector';
import { getBills } from '../../modules/bills/selector';
import { ACTIONS_GENERATOR } from '../../server/API_ACTIONS';
import { ticketsActionCreator } from '../../modules/creditNotes/actions';

const mapStateToProps = state => {
  return {
    creditNotes: getCreditNotes(state),
    accountsById: getAccountsById(state),
    accountsSelected: getAccountsSelected(state),
    accountsSelectedIds: getAccountsSelectedIds(state),
    bills: getBills(state),
    dates: getDates(state),
    creditNotesSelectedId: getCreditNotesSelectedIds(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCreditNotes: () => {
      dispatch(ticketsActionCreator.GET_TICKETS_REQUEST());
    },
    updateCreditNotes: async creditNote => {
      dispatch(ACTIONS_GENERATOR.CREDIT_NOTE.UPDATE_SUCCESS(creditNote));
    },
    selectCreditNote: creditNote => {
      dispatch(creditNotesActionCreator.SELECT(creditNote));
    },
  };
};

export const CreditNotesBillCreate = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditNotesComponent);

const creditNotesActionCreator = {
  GET_CREDIT_NOTES: (accounts, dates) => ({
    type: 'CREDIT_NOTES/GET',
    payload: { accounts, dates },
  }),
  GET_CREDIT_NOTES_SUCCESS: creditNotes => ({
    type: 'CREDIT_NOTES/GET/SUCCESS',
    payload: creditNotes,
  }),
  SELECT: creditNote => ({
    type: 'CREDIT_NOTES/SELECT',
    payload: creditNote,
  }),
};
