import { ObjectID } from 'bson';

export const dateService = {
  getDateFromObjectID: id =>
    new ObjectID(id)
      .getTimestamp()
      .toISOString()
      .slice(0, 10),
  getDateYYYYMMDD: () => new Date().toISOString().slice(0, 10),
};

export const generateLongDate = (dateValue = '') => {
  let dateTimeD = '';
  dateTimeD = dateValue ? new Date(dateValue) : new Date();
  let months = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'aout',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ];
  let dateToShow =
    dateTimeD.getDate() + ' ' + months[dateTimeD.getMonth()] + ' ' + dateTimeD.getFullYear();
  return dateToShow;
};
