import React from "react";
import { theme } from "../../theme";

const styles = {
  buttonStyle: {
    ...theme.typography.textNormal,
    color: theme.colorUsages.white,
    backgroundColor: theme.colorUsages.primary,
    padding: `${theme.getSpacing(2)}px `,
    borderColor: theme.colorUsages.primary
  },
  secondary: {
    color: theme.colorUsages.primary,
    backgroundColor: theme.colorUsages.white,
    border: `solid thin ${theme.colorUsages.primary}`
  },
  success: {
    color: theme.colorUsages.white,
    backgroundColor: theme.colorUsages.success,
    border: `solid thin ${theme.colorUsages.success}`
  },
  warning: {
    color: theme.colorUsages.textColor,
    backgroundColor: theme.colorUsages.warning,
    border: `solid thin ${theme.colorUsages.warning}`
  },
  danger: {
    color: theme.colorUsages.white,
    backgroundColor: theme.colorUsages.danger,
    border: `solid thin ${theme.colorUsages.danger}`
  },
  info: {
    color: theme.colorUsages.white,
    backgroundColor: theme.colorUsages.info,
    border: `solid thin ${theme.colorUsages.info}`
  },
  small: {
    fontSize: theme.fontSizes.small
  }
};

export const Button = ({
  onClick,
  secondary,
  fullWidth,
  children,
  capital,
  success,
  warning,
  danger,
  info,
  small,
  style
}) => (
  <button
    style={Object.assign(
      {},
      styles.buttonStyle,
      secondary && styles.secondary,
      { width: fullWidth ? "100%" : "auto" },
      { textTransform: capital ? "capitalize" : "" },
      success && styles.success,
      warning && styles.warning,
      danger && styles.danger,
      info && styles.info,
      small && styles.small,
      style
    )}
    onClick={onClick}
  >
    {children}
  </button>
);
