import React from "react";
import { theme } from "../../theme";

const styles = {
  label: {
    ...theme.typography.textSemiBold,
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(1),
    marginLeft: theme.getSpacing(1),
    marginRight: theme.getSpacing(2)
  }
};

export const Label = ({ children, style, ...props }) =>
  children ? (
    <label style={{ ...styles.label, ...style }} {...props}>
      {children}
    </label>
  ) : null;
