import React from 'react';
import './App.css';
import './stylesheets/button.scss';
import { Bills } from './pages/bills';
import { Connexion } from './components/Connexion';
import { Accounts } from './pages/accounts';
import { Route, Switch } from 'react-router-dom';
import { airReaderRoutes } from './services/routesService';
import { Layout } from './easy_lib/Layout/Layout';
import { DateFilter } from './components/DateFilter';
import { BillPayerManager } from './pages/billPayerManager';
import { Tickets } from './pages/tickets';
import { CreateBill } from './pages/createBill';
import { CreditNotesBillCreate } from './pages/creditNoteBill';
import { BillsUpdater } from './pages/bills/billsUpdater/index';
import { AccountsSelected } from './components/Layout/accountSelected';
import { Row } from './easy_lib/Layout';
import { States } from './pages/states';
import { CreditNoteStates } from './pages/states/creditNoteStates';
import { WelcomePage } from './pages/welcome/welcome';
import { StatsAndGraphs } from './pages/statsAndGraph/StatsAndGraphs';
import { CreditNoteCreate } from './pages/creditNote';
import { CreditNoteList } from './pages/creditNoteBill/CreditNotesList';
import { CreditNoteBillViewer } from './pages/creditNoteBill/CreditNoteBillViewer';

function App() {
  return (
    <div className="App">
      <Layout>
        <Row>
          <AccountsSelected style={{ flex: 1 }}>
            <Accounts />
          </AccountsSelected>
          <DateFilter style={{ flex: 1 }} />
        </Row>
        <Switch>
          <Route exact path="/" component={WelcomePage} />
          <Route exact path={airReaderRoutes.BILLS.to} component={Bills} />
          {/* <Route path={airReaderRoutes.SET_PAYMENT.to} component={BillPayerManager} /> */}
          <Route exact path={airReaderRoutes.TICKETS.to} component={Tickets} />
          <Route path={airReaderRoutes.BILLS_CREATE.to} component={CreateBill} />
          <Route path={airReaderRoutes.BILLS_UPDATE.to} component={BillsUpdater} />
          <Route
            path={airReaderRoutes.CREDIT_NOTE_BILL_CREATE.to}
            component={CreditNotesBillCreate}
          />
          <Route exact path={airReaderRoutes.CREDIT_NOTE_BILLS.to} component={CreditNoteList} />
          <Route path={airReaderRoutes.CREDIT_NOTE_BILL.to} component={CreditNoteBillViewer} />
          <Route path={airReaderRoutes.TICKET_STATES.to} component={States} />
          <Route path={airReaderRoutes.CREDIT_NOTE_STATE.to} component={CreditNoteStates} />
          <Route path={airReaderRoutes.STATS_AND_GRAPH.to} component={StatsAndGraphs} />
          <Route path={airReaderRoutes.CREDIT_NOTE_CREATE.to} component={CreditNoteCreate} />
        </Switch>
      </Layout>
    </div>
  );
}

export default App;
