export const LOG_IN_REQUEST = 'LOG_IN_REQUEST';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_ERROR = 'LOG_IN_ERROR';
export const LOG_OUT_ACTION = 'LOG_OUT_ACTION';

export const logInRequestActionCreator = userData => {
  return { type: LOG_IN_REQUEST, payload: { userData } };
};

export const logInSuccessActionCreator = token => {
  return { type: LOG_IN_SUCCESS, payload: { token } };
};

export const logInErrorActionCreator = error => {
  return { type: LOG_IN_ERROR, payload: { error: error } };
};

export const logOutRequestActionCreator = () => {
  return { type: LOG_OUT_ACTION };
};
