import { GET_TICKETS_SUCCESS, ITicketsActions, SELECT_TICKET } from './actions';

export const initialState: {
  creditNotesById: {};
  creditNotes: any[];
  creditNotesSelected: any[];
} = {
  creditNotesById: {},
  creditNotes: [],
  creditNotesSelected: [],
};

export const creditNotesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'CREDIT_NOTES/GET/SUCCESS':
      console.log('SUCCESS', action);
      const creditNotesReceived = action.payload;
      const newTicketsIDs = creditNotesReceived.map(creditNote => creditNote._id);
      return {
        ...state,
        creditNotesById: {
          // ...state.creditNotesById,
          ...creditNotesReceived.reduce((newTicketsById, creditNote) => {
            newTicketsById[creditNote._id] = creditNote;
            return newTicketsById;
          }, {}),
        },
        creditNotes: [
          // ...state.creditNotes.filter(creditNote => !newTicketsIDs.includes(creditNote._id)),
          ...creditNotesReceived,
        ],
      };
    case 'CREDIT_NOTES/UPDATE':
      const creditNoteToUpdate = action.payload;
      return {
        ...state,
        creditNotesById: {
          [creditNoteToUpdate._id]: creditNoteToUpdate,
        },
        creditNotes: updateInArray(
          state.creditNotes,
          creditNoteToUpdate,
          creditNote => creditNote._id === creditNoteToUpdate._id
        ),
      };
    case 'CREDIT_NOTES/SELECT':
      const creditNoteToToggle = action.payload;
      let creditNotesSelected = [];
      if (creditNoteToToggle !== 'reset') {
        creditNotesSelected = Array.isArray(creditNoteToToggle)
          ? creditNoteToToggle
          : toggleFromArray(state.creditNotesSelected, action.payload._id, creditNoteToToggle._id);
      }
      return {
        ...state,
        creditNotesSelected,
      };
    default:
      return state;
  }
};

const toggleFromArray = (array, item, comparisonFunction) => {
  const finalArray = [...array];
  const itemToToggle = item;
  const itemIndexInArray = finalArray.indexOf(comparisonFunction);
  if (itemIndexInArray > -1) {
    finalArray.splice(itemIndexInArray, 1);
  } else {
    finalArray.push(itemToToggle);
  }
  return finalArray;
};

const updateInArray = (itemsArray, item, comparisonFunction) => {
  const itemsArrayCopy = [...itemsArray];
  const itemIndexInArray = itemsArrayCopy.findIndex(comparisonFunction);
  if (itemIndexInArray > -1) {
    itemsArrayCopy[itemIndexInArray] = item;
  }
  return itemsArrayCopy;
};
