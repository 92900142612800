import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  GET_TICKETS_REQUEST,
  ticketsActionCreator,
} from './actions';
import { isUserLogged } from '../login/selector';
import { getAccountsSelectedIds } from '../accounts/selector';
import { getDates } from '../dates/selector';
import { getTicketsFromServer } from '../../services/api/tickets';

// Our worker Saga: will perform the async increment task
export function* retrieveTickets(action) {
  console.log('call request');
  const accountsSelectedIds = yield select(getAccountsSelectedIds);
  const dates = yield select(getDates);
  const bills = yield call(getTicketsFromServer, { accounts: accountsSelectedIds, dates });
  yield put(ticketsActionCreator.GET_TICKETS_SUCCESS(bills));
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export function* watchTicketsActionsSync() {
  yield takeLatest(GET_TICKETS_REQUEST, retrieveTickets);
  // yield takeLatest(ADD_BILL_PAYMENT_REQUEST, addBillPaymentSaga);
}
