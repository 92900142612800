const colors = {
  blue: '#2a53a7',
  blueDark: '#1D376F',
  blueLight: '#3b62d8',
  purple: '#6f75ec',
  blackLight: '#333333',
  black: '#000000',
  white: '#ffffff',
  greyLight: '#fcfcfc',
  grey: '#e9e9e9',
  green: '#66bb6a',
  greenLight: '#98ee99',
  greenDark: '#338a3e',
  moonGlow: '#FEFAD4',
  yellow: '#ffe866',
  apricotPeach: '#FCD0BA',
  red: '#f44336',
  redLight: '#ff7961',
  redDark: '#ba000d',
  falcon: '#765D69',
  greyTransparent: '#A3A3A3A3'
};

const mainColors = {
  primary: colors.blue,
  primaryLight: colors.blueLight,
  primaryDark: colors.blueDark,
  secondary: colors.red,
  secondaryLight: colors.redLight,
  secondaryDark: colors.redDark,
  third: colors.moonGlow,
  fourth: colors.apricotPeach,
  fifth: colors.falcon,
  white: colors.white,
};

const fontSizes = {
  small: 14,
  normal: 16,
  large: 20,
  xl: 32,
  h1: 50,
};

const fontWeights = {
  normal: '400',
  semiBold: '600',
  bold: '700',
};

const MARGIN_BASE = 5;
const getSpacing = number => MARGIN_BASE * number;

const colorUsages = {
  ...mainColors,
  textColor: colors.blackLight,
  navBarBackground: mainColors.primaryLight,
  navBarItemHoverBackground: colors.purple,
  navBarItemColorSelected: mainColors.primary,
  shadow: colors.black,
  backgroundColor: colors.greyLight,
  border: colors.grey,
  success: colors.green,
  danger: colors.red,
  error: colors.red,
  info: colors.blue,
  warning: colors.yellow,
  modalBackground: colors.greyTransparent,
};

const mainSizes = {
  navBarHeight: getSpacing(8),
};

const typography = {
  welcomeTitle: {
    fontSize: fontSizes.h1,
    fontWeight: fontWeights.semiBold,
    textShadow: `0 1px ${colorUsages.shadow}`,
  },
  textSmall: {
    fontSizes: fontSizes.small,
  },
  textSemiBold: {
    fontSize: fontSizes.normal,
    fontWeight: fontWeights.semiBold,
  },
  textNormal: {
    fontSize: fontSizes.normal,
  },
  textBold: {
    fontSize: fontSizes.normal,
    fontWeight: fontWeights.bold,
  },
  textLarge: {
    fontSize: fontSizes.large,
  },
  textLargeSemiBold: {
    fontSize: fontSizes.large,
    fontWeight: fontWeights.semiBold,
  },
  textLargeBold: {
    fontSize: fontSizes.large,
    fontWeight: fontWeights.bold,
  },
  title: {
    fontSize: fontSizes.xl,
    fontWeight: fontWeights.semiBold,
  },
};

const styles = {
  title: { ...typography.textLargeBold, color: colorUsages.primary, textAlign: 'center', marginBottom: getSpacing(3) },
  input: {
    border: 'solid 1px #dce4dc',
    backgroundColor: 'white',
    color: 'black',
    padding: getSpacing(1),
    height: getSpacing(7),
    marginTop: getSpacing(1),
    fontFamily: 'inherit',
    ...typography.textNormal,
  },
  listItem: {
    backgroundColor: colorUsages.white,
    height: getSpacing(6),
    alignItems: 'center',
    padding: `${getSpacing(1)}px ${getSpacing(2)}px`,
    borderBottom: `1px solid ${colorUsages.border}`,
    minWidth: 150,
  },
  sectionHeader: {
    borderBottom: "1px solid grey",
    fontWeight: fontWeights.semiBold,
    padding: getSpacing(2),
    display: 'flex',
    alignItems:'center',
    justifyContent: 'space-between'
  }
};

const zIndex = {
  drawer: 5
}

export const theme = {
  colorUsages,
  getSpacing,
  mainSizes,
  fontSizes,
  fontWeights,
  typography,
  styles,
  zIndex,
};
