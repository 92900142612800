import React from 'react';
import { Button } from '../Basics';
import { Modal } from './Modal';

export const ModalButton = ({ buttonValue, children, modalStyle, ...props }) => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Button onClick={() => setModalOpen(true)} {...props}>{buttonValue}</Button>
      <Modal open={isModalOpen} style={modalStyle} onClose={() => setModalOpen(false)}>
        {children(() => setModalOpen(false))}
      </Modal>
    </React.Fragment>
  );
};
