import { connect } from 'react-redux';
import { getAccountsSelected } from '../../modules/accounts/selector';
import { getDates } from '../../modules/dates/selector';
import { getTicketsNotCanceled } from '../../modules/tickets/selector';
import { getFlightDates } from './getFlightDates';
import { StatesComponent } from './states.components';

const mapStateToProps = state => {
  return {
    items: getTicketsNotCanceled(state) || [],
    dates: getDates(state) || { before: '', after: '' },
    accountSelected: getAccountsSelected(state)
      ? getAccountsSelected(state)[0]
      : { name: 'no name' },
    keysPossible,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export const States = connect(
  mapStateToProps,
  mapDispatchToProps
)(StatesComponent);

const keysPossible = {
  "Date d'emission": ticket => ticket.date || '',
  'Date de depart': ticket => getFlightDates(ticket)[0] || '',
  'Date de retour': ticket => getFlightDates(ticket)[1] || '',
  Nom: ticket => ticket.name || '',
  'Compte client': ticket => JSON.stringify(ticket.customerAccount) || '',
  Compagnie: ticket => ticket.companyName || '',
  'Numero billet': ticket => ticket.ticketNumber || '',
  'Numero sans code compagnie': ticket => ticket.ticketNumber ? ticket.ticketNumber.substr(4) : '',
  Parcours: ticket => ticket.trip || '',
  Classe: ticket => '',
  'Numero de projet': ticket =>
    ticket.customerMetaData ? JSON.stringify(ticket.customerMetaData.projectNumber) : '',
  'Bon de commande': ticket =>
    ticket.customerMetaData ? JSON.stringify(ticket.customerMetaData.purchaseOrder) : '',
  'Description projet': ticket =>
    ticket.customerMetaData ? JSON.stringify(ticket.customerMetaData.description) : '',
  Facture: ticket =>
    ticket['billed']
      ? ticket['billed']['bill_number']
        ? ticket['billed']['bill_number']
        : ' '
      : ' ',
  'BC/Trip': ticket => ticket.commandNumber || '',
  PNR: ticket => ticket.PNR || '',
  HT: ticket => (ticket.priceHT || '').toLocaleString('fr'),
  Taxes: ticket => parseInt(ticket.priceTTC || 0, 10) - parseInt(ticket.priceHT, 0, 10),
  'Montant/FCFA': ticket => parseInt(ticket.priceTTC || 0, 10),
  'Frais de service': ticket => parseInt(ticket.fees || 0, 10),
  Total: ticket => parseInt(ticket.total || 0, 10),
  '% commission': ticket => ticket.commissionRate || '',
  'valeur commission': ticket => parseInt(ticket.commissionValue || 0, 10) || '',
  Remarques: ticket => (ticket.remarks ? ticket.remarks.split('\n').join('') : ''),
  Note1: ticket => ticket.note1,
  Annulé: ticket => (ticket.canceled ? 'Annulé' : ''),
};
