import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '../../easy_lib/Basics';
import { ServerService } from '../../server/ServerService';
import { withRouter } from 'react-router';

export const CreditNoteList = withRouter(({ history }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    const fetchBills = async () => {
      const bills = await ServerService.CREDIT_NOTE.GET_BILLS().then(res => res.data);
      dispatch({ type: 'CREDIT_NOTE_BILLS/ADD', bills });
      console.log('bills received', bills);
    };
    fetchBills();
  }, []);
  const creditNoteBills = useSelector(state => state.creditNoteBills.bills);
  console.log('Here we go', creditNoteBills);

  return (
    <List
      data={creditNoteBills}
      ListHeader="Toutes les factures"
      renderItem={creditNoteBill => (
        <div
          onClick={() => history.push(`/credit_notes/bills/${creditNoteBill._id}`)}
          style={{ textDecoration: creditNoteBill.canceled && 'line-through' }}
        >
          {creditNoteBill.billNumber} | {creditNoteBill.creationDate} |{' '}
          {creditNoteBill.customerName} - <span>Voir</span>
        </div>
      )}
    />
  );
});
