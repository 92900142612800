import { Label, Select } from '../Basics';
import React from 'react';
import { theme } from '../../theme';
import { Column, Row } from '../Layout';
import { CheckBox } from '../Basics/CheckBox';

export const InputHandler = ({ label, row, ...props }) =>
  row ? (
    <Row style={{ alignItems: 'baseline' }}>
      <Label>
        {label} {props.required && '*'}
      </Label>
      {getComponentToRender(props)}
    </Row>
  ) : (
    <Column>
      <Label>
        {label} {props.required && '*'}
      </Label>
      {getComponentToRender(props)}
    </Column>
  );

const getComponentToRender = ({ style, ...props }) => {
  switch (props.type) {
    case 'select':
      return <Select {...props} style={{ ...theme.styles.input, ...style }} />;
    case 'checkbox':
      return <CheckBox {...props} checked={props.value} style={{ ...style }} />;
    case 'textarea':
      return <textarea {...props} style={{ ...theme.styles.input, ...style }} />;
    default:
      return <input {...props} style={{ ...theme.styles.input, ...style }} />;
  }
};
