const CREDIT_NOTE_BILLS_ACTIONS = { ADD: 'CREDIT_NOTE_BILLS/ADD' };

const initialState = {
  billsById: {},
  bills: [],
};

export const creditNoteBillReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREDIT_NOTE_BILLS_ACTIONS.ADD:
      const bills = action.bills;
      const newBillsIDs = bills.map(bill => bill._id);

      return {
        ...state,
        billsById: {
          ...state.billsById,
          ...bills.reduce((newBillsById, bill) => {
            newBillsById[bill._id] = bill;
            return newBillsById;
          }, {}),
        },
        bills: [...state.bills.filter(bill => !newBillsIDs.includes(bill._id)), ...bills],
      };
      break;
    default:
      return state;
      break;
  }
};
