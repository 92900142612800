import React from "react";
const styles = {
  checkbox: {}
};
export const CheckBox = ({ checked, style, readOnly, onChange, ...props }) => {
  return (
    <div
      style={{ flex: 1, display: 'flex', padding: 10, alignItems: 'center', ...style }}
      onClick={() =>
        !readOnly &&
        onChange &&
        onChange({
          target: {
            name: props.name,
            value: !checked,
            checked: !checked
          }
        })
      }
    >
      <input
        type="checkbox"
        style={{ ...styles.checkbox }}
        {...props}
        // onChange={e=> console.log(e)}
        checked={checked}
      />
    </div>
  );
};
