import React from 'react';
import { Modal } from '../../easy_lib/Advanced/Modal';

const TEXT_CONST = {
  ACCOUNT_SELECTED: 'Comptes selectionnés',
};

export const withModal = ComponentToShow => ({ value, open, children, onClick, style, ...props }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  React.useEffect(() => {
    // Met à jour le titre du document via l’API du navigateur
    setModalOpen(open > 0);
  }, [open]);

  return (
    <div style={style}>
      <ComponentToShow
        onClick={() => {
          setModalOpen(true);
          onClick && onClick();
        }}
        {...props}
      />

      {modalOpen && (
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        >
          {children}
        </Modal>
      )}
    </div>
  );
};
