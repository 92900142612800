import { IRootReducer } from '../rootReducer';
import { getAccountsSelected, getAccountsSelectedIds } from '../accounts/selector';
import { getDates, getDatesYYMMDD } from '../dates/selector';

export const getTickets = (state: IRootReducer) => {
  const accountsSelectedIds = getAccountsSelectedIds(state);
  const dates = getDatesYYMMDD(state);
  console.log(dates);
  return Object.values(state.tickets.ticketsById)
    .filter(
      ticket =>
        accountsSelectedIds.includes(ticket.customerId) &&
        ticket.date >= dates.after &&
        ticket.date <= dates.before
    )
    .sort((a, b) => (a.date > b.date ? 1 : -1));
};
export const getTicketById = (state: IRootReducer, id) => state.tickets.ticketsById[id];
export const getTicketsSelectedIds = (state: IRootReducer) => state.tickets.ticketsSelected;
export const getTicketsSelected = (state: IRootReducer) =>
  state.tickets.ticketsSelected.map(ticketId => state.tickets.ticketsById[ticketId]);
export const getTicketsNotCanceled = (state: IRootReducer) => {
  const accountsSelectedIds = getAccountsSelectedIds(state);
  const dates = getDatesYYMMDD(state);
  return Object.values(state.tickets.ticketsById)
    .filter(
      ticket =>
        !ticket.canceled &&
        accountsSelectedIds.includes(ticket.customerId) &&
        ticket.date >= dates.after &&
        ticket.date <= dates.before
    )
    .sort((a, b) => (a.date > b.date ? 1 : -1));
};
export const getTicketsWithCreditNote = (state: IRootReducer) =>
  Object.values(state.tickets.ticketsById).filter(ticket => ticket.creditNoteMetaData);
