import { theme } from '../../theme';
import React, { useEffect, useState } from 'react';
import { Button } from '../Basics';
import { InputHandler } from './InputHandler';

export const FormGenerator = ({
  titleStyle,
  buttonStyle,
  title,
  items,
  onValidateAction,
  onValidateText = 'Valider',
  style = { textAlign: 'left' },
  initialData,
  onChange,
}) => {
  const [formValues, setFormValues] = useState(initialData ? { ...initialData } : {});
  useEffect(() => {
    setFormValues(initialData || {});
  }, [initialData]);

  return (
    <div
      style={{ minWidth: 250, ...style }}
      onKeyPress={e => {
        if (e.key === 'Enter' && onValidateAction) {
          validateData(items, formValues, onValidateAction);
        }
      }}
      tabIndex="0"
    >
      <div style={{ ...theme.styles.title, ...titleStyle }}>{title}</div>
      {items.map(item => (
        <InputHandler
          {...item}
          key={item.name}
          onChange={e => {
            onChange
              ? setFormValues(onChange({ ...formValues, [e.target.name]: e.target.value }))
              : setFormValues({ ...formValues, [e.target.name]: e.target.value });
          }}
          value={formValues[item.name]}
        />
      ))}
      {onValidateAction && (
        <Button
          fullWidth
          style={buttonStyle}
          onClick={() => validateData(items, formValues, onValidateAction)}
        >
          {onValidateText}
        </Button>
      )}
    </div>
  );
};

FormGenerator.defaultProps = {
  onValidateText: 'Valider',
};

const validateData = (formItems, formValues, validateAction) => {
  const requiredItems = formItems.filter(item => item.required);
  for (let item of requiredItems) {
    if (formValues[item.name] === undefined) {
      alert(`Veuillez remplir le champ "${item.label}" svp `);
      return false;
    }
  }
  validateAction(formValues);
};

export const ExternalFormGenerator = ({
  titleStyle,
  buttonStyle,
  title,
  items,
  onValidateAction,
  onValidateText = 'Valider',
  style = { textAlign: 'left' },
  initialData,
  values,
  onChange,
}) => {
  const [formValues, setFormValues] = [values, onChange];

  return (
    <div
      style={{ minWidth: 250, ...style }}
      onKeyPress={e => {
        if (e.key === 'Enter' && onValidateAction) {
          validateData(items, formValues, onValidateAction);
        }
      }}
      tabIndex="0"
    >
      <div style={{ ...theme.styles.title, ...titleStyle }}>{title}</div>
      {items.map(item => (
        <InputHandler {...item} key={item.name} onChange={onChange} value={formValues[item.name]} />
      ))}
      {onValidateAction && (
        <Button
          fullWidth
          style={buttonStyle}
          onClick={() => validateData(items, formValues, onValidateAction)}
        >
          {onValidateText}
        </Button>
      )}
    </div>
  );
};
