export const GET_BILLS_REQUEST = 'GET_BILLS_REQUEST';
export const GET_BILLS_SUCCESS = 'GET_BILLS_SUCCESS';
export const GET_BILLS_ERROR = 'GET_BILLS_ERROR';
export const SELECT_BILL = 'SELECT_BILL';
export const SELECT_MULTI_BILL = 'SELECT_MULTI_BILL';
export const ADD_BILL_PAYMENT_REQUEST = 'ADD_BILL_PAYMENT_REQUEST';
export const ADD_BILL_PAYMENT_SUCCESS = 'ADD_BILL_PAYMENT_SUCCESS';
export const CREATE_BILL = 'BILLS/CREATE';
export const CREATE_BILL_SUCCESS = 'BILLS/CREATE/SUCCESS';

export const BILLS_ACTIONS = {
  GET_BILLS_REQUEST,
  GET_BILLS_SUCCESS,
  GET_BILLS_ERROR,
  SELECT_BILL,
  SELECT_MULTI_BILL,
  ADD_BILL_PAYMENT_SUCCESS,
  CREATE_BILL,
  CREATE_BILL_SUCCESS
};

export type BILLS_ACTIONS_TYPE = typeof BILLS_ACTIONS;

interface IBillsSuccess {
  type: 'GET_BILLS_SUCCESS';
  payload: { bills: any[] };
}
interface IBillsRequest {
  type: 'GET_BILLS_REQUEST';
}
interface IBillsSelect {
  type: 'SELECT_BILL';
  payload: { bill: any };
}
interface IMultiBillsSelect {
  type: 'SELECT_MULTI_BILL';
  payload: any;
}
interface IBillsError {
  type: 'GET_BILLS_ERROR';
}
interface IBillsAddBillPaymentSuccess {
  type: 'ADD_BILL_PAYMENT_SUCCESS';
  payload: { paymentDetails: any };
}
interface IBillsCreate {
  type: 'BILLS/CREATE';
  payload: any;
}
interface IBillsCreateSuccess {
  type: 'BILLS/CREATE/SUCCESS';
  payload: any;
}

export type IBillsActions =
  | IBillsSuccess
  | IBillsSelect
  | IBillsRequest
  | IBillsError
  | IBillsAddBillPaymentSuccess
  | IMultiBillsSelect
  | IBillsCreate
  | IBillsCreateSuccess;

// export const billsActionCreator : {[x:string] : (y?: any) => IBillsActions} = {
export const billsActionCreator = {
  GET_BILLS_REQUEST: () => ({ type: GET_BILLS_REQUEST }),
  GET_BILLS_SUCCESS: (bills: any[]) => ({ type: GET_BILLS_SUCCESS, payload: { bills } }),
  GET_BILLS_ERROR: () => ({ type: GET_BILLS_ERROR }),
  SELECT_BILL: bill => ({ type: SELECT_BILL, payload: { bill } }),
  SELECT_MULTI_BILL: bill => ({ type: SELECT_MULTI_BILL, payload: bill }),
  ADD_BILL_PAYMENT_REQUEST: paymentDetails => ({
    type: ADD_BILL_PAYMENT_REQUEST,
    payload: paymentDetails,
  }),
  ADD_BILL_PAYMENT_SUCCESS: paymentDetails => ({
    type: ADD_BILL_PAYMENT_SUCCESS,
    payload: paymentDetails,
  }),
  CREATE_BILL: (data: {}) => ({
    type: CREATE_BILL,
    payload: data
  }),
  CREATE_BILL_SUCCESS: (data: any) => ({
    type: CREATE_BILL_SUCCESS,
    payload: data
  }),
};
