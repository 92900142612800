import axios from "axios";
import { Subject } from 'rxjs';
import { __TOKEN_KEY__ } from "./SERVER_CONST";
import { tokenService } from '../services/tokenService';
// Add a request interceptor
const isLocal = true;

export const showConnexionModalSubject = new Subject();


let axiosInstance = axios.create({
  baseURL: '/api'
});

axiosInstance.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem(__TOKEN_KEY__);
    console.log("token retrieved from local storage", token);

    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

//
axiosInstance.interceptors.response.use(
  response => {
    // Do something with response data
    console.log(response);
    switch (response.status) {
      case 200:
        return response;
      default:
        console.log("unknwon error");
        return response;
    }
  },
  function(error) {
    // Do something with response error
    console.log("error", error.response);
    if (error.response) {

      switch (error.response.status) {
        case 400:
          console.log(error.response.data, 'to alert');
          error.response && error.response.data && alert(error.response.data);
          return Promise.reject(error);
        case 401:
          showConnexionModalSubject.next(tokenService.getLastUserName());
          console.log(error.response.data, 'to alert', tokenService.getLastUserName());
          return Promise.reject(error);
        default :
          return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);
//
//

console.log(axiosInstance);

export default axiosInstance;
