import React from 'react';

export const LOCAL_STORAGE_KEYS = {
  LAST_BILL_DATA: 'LAST_BILL_DATA',
};

const getItems = key => (localStorage.getItem(key) && JSON.parse(localStorage.getItem(key))) || [];
const getLastItem = key => getItems(key).slice(-1)[0];

export const localStorageService = {
  getLastItem,
  storeItem: (key, item) => {
    const knowItems = getItems(key);
    const lastItem = knowItems.slice(-1)[0];
    if (JSON.stringify(lastItem) !== JSON.stringify(item)) {
      const newItems = [...knowItems, item].slice(Math.max(knowItems.length - 5, 0));
      localStorage.setItem(key, JSON.stringify(newItems));
    }
  },
};

export const LocalStorageItemSelector = ({ localStorageKey, onItemClick }) => (
  <div style={{ marginLeft: 20 }}>
    <h2>Historique :</h2>
    {getItems(localStorageKey).map(item => (
      <div onClick={() => onItemClick(item)}>{Object.keys(item).map(itemKey => `${item[itemKey]} `)}</div>
    ))}
  </div>
);
