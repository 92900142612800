export const GET_TICKETS_REQUEST = 'GET_TICKETS_REQUEST';
export const GET_TICKETS_SUCCESS = 'GET_TICKETS_SUCCESS';
export const GET_TICKETS_ERROR = 'GET_TICKETS_ERROR';
export const SELECT_TICKET = 'SELECT_TICKET';

export const TICKETS_ACTIONS = {
  GET_TICKETS_REQUEST,
  GET_TICKETS_SUCCESS,
  GET_TICKETS_ERROR,
  SELECT_TICKET,
};

interface ITicketsSuccess {
  type: 'GET_TICKETS_SUCCESS';
  payload: any[];
}
interface ITicketsRequest {
  type: 'GET_TICKETS_REQUEST';
}
interface ITicketSelect {
  type: 'SELECT_TICKET';
  payload: any;
}

export type ITicketsActions =
  | ITicketsSuccess
  | ITicketsRequest
  | ITicketSelect;

// export const billsActionCreator : {[x:string] : (y?: any) => IBillsActions} = {
export const ticketsActionCreator = {
  GET_TICKETS_REQUEST: () => ({ type: GET_TICKETS_REQUEST }),
  GET_TICKETS_SUCCESS: (tickets: any[]) => ({ type: GET_TICKETS_SUCCESS, payload: tickets }),
  GET_TICKETS_ERROR: () => ({ type: GET_TICKETS_ERROR }),
  SELECT_TICKET: (ticket: any) => ({ type: SELECT_TICKET, payload: ticket }),
};
