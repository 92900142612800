export const airReaderRoutes = {
  // SET_PAYMENT: {text: 'GERER PAIEMENT', to: '/payment'},
  TICKETS: { text: 'CHARGER', to: '/tickets', group: 'tickets' },
  BILLS_CREATE: { text: 'CREER FACTURE', to: '/bills/create', group: 'tickets' },
  BILLS: { text: 'VOIR FACTURES', to: '/bills', group: 'tickets' },
  CREDIT_NOTE_CREATE: { text: 'CREER AVOIR', to: '/credit_notes/create', group: 'credit_notes' },
  BILLS_UPDATE: { text: '', to: '/bills/update' },
  CREDIT_NOTE_BILL_CREATE: {
    text: 'CREER FACTURE AVOIR',
    to: '/credit_notes/bills/create',
    group: 'credit_notes',
  },
  CREDIT_NOTE_BILLS: { text: 'VOIR FACTURES', to: '/credit_notes/bills', group: 'credit_notes' },
  CREDIT_NOTE_BILL: { text: '', to: '/credit_notes/bills/:id', group: 'credit_notes' },
  TICKET_STATES: { text: 'ETATS BILLETS', to: '/states/tickets', group: 'states' },
  CREDIT_NOTE_STATE: { text: 'ETATS AVOIRS', to: '/states/credit_notes', group: 'states' },
  STATS_AND_GRAPH: { text: '', to: '/statsAndGraphs', group: 'states' },
};

export const airReaderRoutesArray = Object.keys(airReaderRoutes).reduce(
  (final, key) => (airReaderRoutes[key].text ? [...final, airReaderRoutes[key]] : final),
  []
);

export const airReaderRoutesArrayGroupedByCategory = [
  {
    type: 'section',
    text: 'TICKETS',
    items: airReaderRoutesArray.filter(x => x.group === 'tickets'),
  },
  {
    type: 'section',
    text: 'AVOIRS',
    items: airReaderRoutesArray.filter(x => x.group === 'credit_notes'),
  },
  {
    type: 'section',
    text: 'ETATS',
    items: airReaderRoutesArray.filter(x => x.group === 'states'),
  },
  ...airReaderRoutesArray.filter(x => !x.group),
];
